import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { LinearProgress, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  roundReportSelectors,
  roundProductsSelectors,
} from "../../../../../../store/games/factorySelectors";
import {
  factoryApi,
  roundHubsAddOne,
  roundHubsProductsAddOne,
  roundProductsAddOne,
  roundReportAddDefectTvs,
  roundTransportsAddOne,
} from "../../../../../../store/games/factorySlice";
import RoundReport from "./RoundReportLayout";
import { productsInitialState } from "../../../../../../other/productsConstants";

const RoundReportWrapper = () => {
  const dispatch = useDispatch();
  const selectedRoundId = useSelector((state) => state.factory.selectedRoundId);
  const roundReport = useSelector((state) =>
    roundReportSelectors.selectById(state, selectedRoundId)
  );
  const roundProducts = useSelector((state) =>
    roundProductsSelectors.selectById(state, selectedRoundId)
  );

  useEffect(() => {
    let promise = null;

    if (roundReport === undefined && selectedRoundId !== null) {
      promise = dispatch(
        factoryApi.getRoundReport({ roundId: selectedRoundId })
      );

      Promise.all([promise]).then((result) => {
        if (
          result[0].hasOwnProperty("error") &&
          result[0].error.name === "AbortError"
        ) {
          return;
        }
        (async () => {
          const payload = await dispatch(
            factoryApi.roundProductInfo({ roundId: selectedRoundId })
          ).unwrap();
          if (payload.status === "ok") {
            const hubsProducts = {};
            const products = productsInitialState.reduce((obj, item) => {
              return {
                ...obj,
                [item.id]: {
                  total: 0,
                  totalDefect: 0,
                  used: 0,
                  usedNonDefect: 0,
                  usedDefect: 0,
                  defectControlFail: 0,
                  nonDefectControlFail: 0,
                  defectWithoutControl: 0,
                },
              };
            }, {});

            const gameOrdersDefectTvs = {};

            payload.data.gameProducts.forEach((product) => {
              const id = product.productId;
              products[id].total++;
              if (id === 24 && product.gameOrderId !== null) {
                if (!gameOrdersDefectTvs.hasOwnProperty(product.gameOrderId)) {
                  gameOrdersDefectTvs[product.gameOrderId] = 0;
                }
                if (product.isDefect === "Y") {
                  gameOrdersDefectTvs[product.gameOrderId]++;
                }
              }

              if (product.isDefect === "Y") {
                products[id].totalDefect++;
              }
              // if (product.isDefect === "Y" && product.isCheckDefect === "N") {
              //   products[id].defectControlFail++;
              // }
              // if (product.isDefect === "N" && product.isCheckDefect === "Y") {
              //   products[id].nonDefectControlFail++;
              // }
              // if (product.isDefect === "Y" && product.isCheckDefect === null) {
              //   products[id].defectWithoutControl++;
              // }
              // if (product.isUsed === "Y") {
              //   products[id].used++;
              // }
              // if (product.isUsed === "Y" && product.isDefect === "N") {
              //   products[id].usedNonDefect++;
              // }
              if (product.isUsed === "Y" && product.isDefect === "Y") {
                products[id].usedDefect++;
              }

              if (
                product.isUsed === "N" &&
                product.gameHubId !== undefined &&
                product.gameHubId !== null
              ) {
                if (!hubsProducts.hasOwnProperty(product.gameHubId)) {
                  hubsProducts[product.gameHubId] = productsInitialState.reduce(
                    (obj, item) => {
                      return {
                        ...obj,
                        [item.id]: 0,
                      };
                    },
                    {}
                  );
                }
                hubsProducts[product.gameHubId][id] += 1;
              }
            });

            dispatch(
              roundReportAddDefectTvs({
                id: selectedRoundId,
                gameOrdersDefectTvs,
              })
            );
            dispatch(
              roundProductsAddOne({ id: selectedRoundId, products: products })
            );
            dispatch(
              roundHubsProductsAddOne({
                id: selectedRoundId,
                hubsProducts: hubsProducts,
              })
            );
            dispatch(
              roundHubsAddOne({
                id: selectedRoundId,
                hubs: payload.data.gameHubs.sort((a, b) => a.hubId - b.hubId),
              })
            );
            dispatch(
              roundTransportsAddOne({
                id: selectedRoundId,
                transports: payload.data.gameTransports,
              })
            );
          }
        })();
      });
    }
    return () => {
      if (promise !== null) {
        promise.abort();
      }
    };
  }, [selectedRoundId, roundReport, dispatch]);

  if (roundProducts === undefined || roundReport === undefined)
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant={"h6"}>Загрузка результатов раунда...</Typography>
        </Grid>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={12} />
        <Grid item xs={12} />
        <Grid item xs={12} />
        <Grid item xs={12} />
      </Grid>
    );

  return <RoundReport />;
};

export default RoundReportWrapper;
