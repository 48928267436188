import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { roundReportSelectors } from "../../../../../../../store/games/factorySelectors";
import { scales, titleFont, tooltip } from "./defaults";
import color from "color";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      text: "Поставки ТВС по раундам",
      font: titleFont,
    },
    tooltip,
  },
  scales: {
    x: {
      ...scales.x,
      stacked: true,
      maxBarThickness: 20,
    },
    y: {
      stacked: true,
    },
  },
};

const TvsByRounds = () => {
  const theme = useTheme();
  const roundsResults = useSelector(roundReportSelectors.selectAll);

  const data = roundsResults.reduce(
    (result, round) => {
      result.labels.push(`Раунд №${round.number}`);
      result.datasets[0].data.push(round.resultTvsTrue);
      result.datasets[1].data.push(round.resultTvs - round.resultTvsTrue);
      return result;
    },
    {
      labels: [],

      datasets: [
        {
          label: "Качественные ТВС",
          data: [],
          maxBarThickness: 120,
        },
        {
          label: "Бракованные ТВС",
          data: [],
          maxBarThickness: 120,
          backgroundColor: color(theme.palette.error.light)
            .lighten(0.3)
            .string(),
          borderColor: color(theme.palette.error.main).lighten(0.3).string(),
        },
      ],
    }
  );
  return <Bar options={options} data={data} />;
};

export default TvsByRounds;
