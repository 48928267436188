import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  productsSelectors,
  roundHubsProductsSelectors,
  roundHubsSelectors,
} from "../../../../../../../store/games/factorySelectors";
import { useSelector } from "react-redux";
import { productsImages } from "../../../../../../../other/productsConstants";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";

const mapHubsNames = {
  1: "Центральный склад",
  2: "Цех №1",
  3: "Цех №2",
  4: "Цех №3",
  5: "Цех №4",
  6: "Цех №5",
};

const HubsInfo = () => {
  const selectedRoundId = useSelector((state) => state.factory.selectedRoundId);
  const productsEntities = useSelector(productsSelectors.selectEntities);
  const productsIds = useSelector(productsSelectors.selectIds);
  const roundHubs = useSelector((state) =>
    roundHubsSelectors.selectById(state, selectedRoundId)
  ).hubs;
  const roundHubsProducts = useSelector((state) =>
    roundHubsProductsSelectors.selectById(state, selectedRoundId)
  ).hubsProducts;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={"h6"}>НЗП на складах</Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: 440,
          }}
        >
          <Table
            stickyHeader
            sx={{
              minWidth: 640,
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Продукт</TableCell>
                {roundHubs.map((roundHub) => {
                  if (roundHub.hubId > 6) {
                    return null;
                  }
                  return (
                    <TableCell key={roundHub.id}>
                      {mapHubsNames[roundHub.hubId]}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {productsIds.map((productId) => {
                return (
                  <TableRow key={productId}>
                    <TableCell
                      sx={{
                        padding: 0,
                        paddingLeft: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <img
                          width={"48px"}
                          height={"48px"}
                          src={productsImages[productId]}
                          alt={"productImage"}
                        />
                        <Typography>
                          {productsEntities[productId].data.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    {roundHubs.map((roundHub) => {
                      if (roundHub.hubId > 6) {
                        return null;
                      }
                      let value = "";
                      if (roundHubsProducts.hasOwnProperty(roundHub.id)) {
                        if (roundHubsProducts[roundHub.id][productId] !== 0) {
                          value = roundHubsProducts[roundHub.id][productId];
                        }
                      }
                      return (
                        <TableCell key={roundHub.id}>
                          <Typography>{value}</Typography>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default HubsInfo;
