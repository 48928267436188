import {
  bonusesAdapter,
  botsAdapter,
  roundReportAdapter,
  gamePlayersAdapter,
  gameRoundsAdapter,
  gamesAdapter,
  jobsAdapter,
  productsAdapter,
  roundProductsAdapter,
  hubsAdapter,
  transportsAdapter,
  roundHubsAdapter,
  roundTransportsAdapter,
  roundHubsProductsAdapter,
  gameInvitesAdapter,
} from "./factoryAdapters";

export const jobsSelectors = jobsAdapter.getSelectors(
  (state) => state.factory.jobs
);

export const hubsSelectors = hubsAdapter.getSelectors((state) => {
  return state.factory.hubs;
});

export const transportsSelectors = transportsAdapter.getSelectors((state) => {
  return state.factory.transports;
});

export const bonusesSelectors = bonusesAdapter.getSelectors(
  (state) => state.factory.bonuses
);

export const botsSelectors = botsAdapter.getSelectors(
  (state) => state.factory.bots
);

export const gamesSelectors = gamesAdapter.getSelectors(
  (state) => state.factory.games
);
export const gameInvitesSelectors = gameInvitesAdapter.getSelectors(
  (state) => state.factory.gameInvites
);

export const gamePlayersSelectors = gamePlayersAdapter.getSelectors(
  (state) => state.factory.gamePlayers
);

export const gameRoundsSelectors = gameRoundsAdapter.getSelectors(
  (state) => state.factory.gameRounds
);

export const productsSelectors = productsAdapter.getSelectors(
  (state) => state.factory.products
);

export const roundProductsSelectors = roundProductsAdapter.getSelectors(
  (state) => state.factory.roundProducts
);

export const roundReportSelectors = roundReportAdapter.getSelectors((state) => {
  return state.factory.roundReport;
});

export const roundHubsSelectors = roundHubsAdapter.getSelectors((state) => {
  return state.factory.roundHubs;
});

export const roundTransportsSelectors = roundTransportsAdapter.getSelectors(
  (state) => {
    return state.factory.roundTransports;
  }
);

export const roundHubsProductsSelectors = roundHubsProductsAdapter.getSelectors(
  (state) => {
    return state.factory.roundHubsProducts;
  }
);
