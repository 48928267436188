import React from "react";
import { useSelector } from "react-redux";
import { gamePlayersSelectors } from "../../../../../../../store/games/factorySelectors";

const PlayersOnlineCount = () => {
  const players = useSelector(gamePlayersSelectors.selectAll);
  let countOnline = 0;
  let countAll = players.length;
  players.forEach((player) => {
    const seconds = Math.floor(Date.now() / 1000 - player.datetimeOnlineUnix);
    if (seconds < 20) {
      countOnline += 1;
    }
  });
  return <>{`${countOnline} / ${countAll}`}</>;
};

export default PlayersOnlineCount;
