import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import factoryService from "../../services/factoryService";
import {
  botsInitialState,
  factoryAppId,
  fetchStatuses,
  jobsSortOrder,
} from "../../other/constants";
import camelCase from "camelcase";
import camelcaseKeys from "camelcase-keys";
import {
  bonusesAdapter,
  botsAdapter,
  roundReportAdapter,
  gamePlayersAdapter,
  gameRoundsAdapter,
  gamesAdapter,
  jobsAdapter,
  productsAdapter,
  roundProductsAdapter,
  transportsAdapter,
  hubsAdapter,
  roundHubsAdapter,
  roundTransportsAdapter,
  roundHubsProductsAdapter,
  gameInvitesAdapter,
} from "./factoryAdapters";
import { calcFinanceResults, calcOrdersResults } from "./factoryUtils";
import hubService from "../../services/hubService";
import { productsInitialState } from "../../other/productsConstants";

const getAppInfo = createAsyncThunk("factory/appInfo", async () => {
  const response = await factoryService.appInfo();
  return response.data;
});

const getJobs = createAsyncThunk("factory/getJobs", async () => {
  const response = await factoryService.jobs();
  return response.data;
});

const getBonusesInfo = createAsyncThunk("factory/getBonusesInfo", async () => {
  const response = await factoryService.bonusInfo();
  return response.data;
});

const getGameInfo = createAsyncThunk(
  "factory/getGameInfo",
  async ({ gameId }) => {
    const response = await factoryService.gameInfo({ gameId });
    return response.data;
  }
);

const listInvites = createAsyncThunk(
  "factory/listInvites",
  async ({ appId, tag }) => {
    const response = await hubService.listInvites({ appId, tag });
    return response.data;
  }
);

const getRoundInfo = createAsyncThunk(
  "factory/getRoundInfo",
  async ({ roundId }) => {
    const response = await factoryService.roundInfo({ roundId });
    return response.data;
  }
);
const roundProductInfo = createAsyncThunk(
  "factory/roundProductInfo",
  async ({ roundId }) => {
    const response = await factoryService.roundProductInfo({ roundId });
    return response.data;
  }
);

const getRoundReport = createAsyncThunk(
  "factory/getRoundReport",
  async ({ roundId }) => {
    const response = await factoryService.roundReport({ roundId });
    return response.data;
  }
);

const getGamePlayers = createAsyncThunk(
  "factory/getGamePlayers",
  async ({ gameId }) => {
    const response = await factoryService.gamePlayers({ gameId });
    return response.data;
  }
);

const getGameRounds = createAsyncThunk(
  "factory/getGameRounds",
  async ({ gameId }) => {
    const response = await factoryService.gameRounds({ gameId });
    return response.data;
  }
);

const getGames = createAsyncThunk("factory/getGames", async () => {
  const response = await factoryService.games();
  return response.data;
});

const createGame = createAsyncThunk("factory/createGame", async () => {
  const response = await factoryService.createGame();
  return response.data;
});

const getGameReport = createAsyncThunk(
  "factory/getGameReport",
  async ({ gameId }) => {
    const response = await factoryService.gameReport({ gameId });
    return response.data;
  }
);

export const factorySlice = createSlice({
  name: "factory",
  initialState: {
    appId: factoryAppId,
    appInfoLoading: fetchStatuses.idle,
    gameInfo: {
      loading: fetchStatuses.idle,
    },
    jobs: jobsAdapter.getInitialState(),
    bonuses: bonusesAdapter.getInitialState(),
    bots: botsAdapter.addMany(botsAdapter.getInitialState(), botsInitialState),
    transports: transportsAdapter.getInitialState(),
    hubs: hubsAdapter.getInitialState(),
    games: gamesAdapter.getInitialState({ loading: fetchStatuses.idle }),
    gameRounds: gameRoundsAdapter.getInitialState({
      loading: fetchStatuses.idle,
    }),
    gamePlayers: gamePlayersAdapter.getInitialState({
      loading: fetchStatuses.idle,
    }),
    gameInvites: gameInvitesAdapter.getInitialState({
      loading: fetchStatuses.idle,
    }),
    products: productsAdapter.addMany(
      productsAdapter.getInitialState(),
      productsInitialState
    ),
    roundProducts: roundProductsAdapter.getInitialState(),
    roundHubs: roundHubsAdapter.getInitialState(),
    roundTransports: roundTransportsAdapter.getInitialState(),
    roundReport: roundReportAdapter.getInitialState({
      loading: fetchStatuses.idle,
    }),
    roundHubsProducts: roundHubsProductsAdapter.getInitialState(),
    selectedRoundId: null,
    activeRoundId: null,
    activeRound: null,
  },
  reducers: {
    setActiveRoundId: (state, action) => {
      state.activeRoundId = action.payload.id;
    },
    setActiveRound: (state, action) => {
      state.activeRound = action.payload.activeRound;
    },
    setSelectedRoundId: (state, action) => {
      state.selectedRoundId = action.payload.id;
    },
    gamePlayersUpdateOne: (state, action) => {
      gamePlayersAdapter.updateOne(state.gamePlayers, action);
    },
    gamePlayersUpdateMany: (state, action) => {
      gamePlayersAdapter.upsertMany(state.gamePlayers, action.payload);
    },
    gameRoundsUpdateOne: (state, action) => {
      gameRoundsAdapter.updateOne(state.gameRounds, action);
    },
    gameRoundsRemoveOne: (state, action) => {
      gameRoundsAdapter.removeOne(state.gameRounds, action);
    },
    removeGameData: (state, action) => {
      bonusesAdapter.setAll(
        state.bonuses,
        state.bonuses.ids.map((bonusId) => {
          return { ...state.bonuses.entities[bonusId], checked: false };
        })
      );
      gameRoundsAdapter.removeAll(state.gameRounds);
      gamePlayersAdapter.removeAll(state.gamePlayers);
      gameInvitesAdapter.removeAll(state.gameInvites);
      productsAdapter.setAll(state.products, productsInitialState);
      roundProductsAdapter.removeAll(state.roundProducts);
      roundTransportsAdapter.removeAll(state.roundTransports);
      roundHubsAdapter.removeAll(state.roundHubs);
      roundHubsProductsAdapter.removeAll(state.roundHubsProducts);
      roundReportAdapter.removeAll(state.roundReport);
      botsAdapter.setAll(state.bots, botsInitialState);
      state.selectedRoundId = null;
      state.activeRoundId = null;
      state.activeRound = null;
      state.gameInfo = {
        loading: fetchStatuses.idle,
      };
    },
    bonusesUpdateOne: (state, action) => {
      bonusesAdapter.updateOne(state.bonuses, action);
    },
    roundProductsAddOne: (state, action) => {
      // Добавить только если открыта вкладка текущего раунда
      if (action.payload.id === state.selectedRoundId) {
        roundProductsAdapter.addOne(state.roundProducts, action);
      }
    },
    roundHubsAddOne: (state, action) => {
      // Добавить только если открыта вкладка текущего раунда
      if (action.payload.id === state.selectedRoundId) {
        roundHubsAdapter.addOne(state.roundHubs, action);
      }
    },
    roundHubsProductsAddOne: (state, action) => {
      // Добавить только если открыта вкладка текущего раунда
      if (action.payload.id === state.selectedRoundId) {
        roundHubsProductsAdapter.addOne(state.roundHubsProducts, action);
      }
    },
    roundTransportsAddOne: (state, action) => {
      // Добавить только если открыта вкладка текущего раунда
      if (action.payload.id === state.selectedRoundId) {
        roundTransportsAdapter.addOne(state.roundTransports, action);
      }
    },
    botsUpdateMany: (state, action) => {
      botsAdapter.updateMany(state.bots, action);
    },
    roundReportAddDefectTvs: (state, action) => {
      // Добавить только если открыта вкладка текущего раунда
      const roundReport = state.roundReport.entities[action.payload.id];
      if (roundReport !== undefined)
        roundReportAdapter.updateOne(state.roundReport, {
          id: action.payload.id,
          changes: {
            ...roundReport,
            gameOrders: roundReport.gameOrders.map((order) => {
              return {
                ...order,
                defectTvs: action.payload.gameOrdersDefectTvs.hasOwnProperty(
                  order.id
                )
                  ? action.payload.gameOrdersDefectTvs[order.id]
                  : 0,
              };
            }),
          },
        });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAppInfo.pending, (state) => {
        state.appInfoLoading = fetchStatuses.pending;
      })
      .addCase(getAppInfo.fulfilled, (state, action) => {
        state.appInfoLoading = fetchStatuses.fulfilled;
        jobsAdapter.setAll(state.jobs, action.payload.data.jobs);
        jobsAdapter.upsertMany(state.jobs, jobsSortOrder);

        const arr = action.payload.data.bonus.map((item) => {
          return { ...item, id: camelCase(item.id), checked: false };
        });
        bonusesAdapter.setAll(state.bonuses, arr);

        const productsEntities = state.products.entities;

        productsAdapter.upsertMany(
          state.products,
          action.payload.data.products.map((product) => {
            return {
              id: String(product.id),
              data: {
                ...productsEntities[product.id].data,
                ...product,
              },
            };
          })
        );
        hubsAdapter.setAll(state.hubs, action.payload.data.hubs);
        transportsAdapter.setAll(
          state.transports,
          action.payload.data.transports
        );
      })
      .addCase(getAppInfo.rejected, (state) => {
        state.appInfoLoading = fetchStatuses.rejected;
      })
      .addCase(getGameInfo.pending, (state) => {
        state.gameInfo.loading = fetchStatuses.pending;
      })
      .addCase(getGameInfo.fulfilled, (state, action) => {
        state.gameInfo = {
          loading: fetchStatuses.fulfilled,
          countRounds: action.payload.data.countRounds,
          countPlayers: action.payload.data.countPlayers,
          datetimeStarted: action.payload.data.datetimeStartedUnix,
          datetimeEnded: action.payload.data.datetimeEndedUnix,
        };

        let gamePlayers = action.payload.data.players.map((item) => {
          return {
            ...item,
          };
        });
        gamePlayersAdapter.upsertMany(state.gamePlayers, gamePlayers);

        let activeRoundId = null;

        action.payload.data.rounds.forEach((round) => {
          if (round.datetimeEnded === null) {
            activeRoundId = round.id;
            state.activeRoundId = activeRoundId;
          }
        });

        const entity = gameRoundsAdapter.setAll(
          state.gameRounds,
          action.payload.data.rounds
        );
        if (entity.ids.length > 0) {
          if (activeRoundId === entity.ids[0]) {
            if (entity.ids.length > 1) {
              state.selectedRoundId = entity.ids[1];
            }
          } else {
            state.selectedRoundId = entity.ids[0];
          }

          const round = entity.entities[entity.ids[0]];
          let roundPlayers = round.roundPlayers.map((item) => {
            return {
              id: item.playerId,
              jobId: item.jobId,
            };
          });
          gamePlayersAdapter.upsertMany(state.gamePlayers, roundPlayers);

          let bonuses = state.bonuses.ids;
          bonuses = bonuses.map((item) => {
            return {
              id: item,
              checked: round[item] === "Y",
            };
          });
          bonusesAdapter.upsertMany(state.bonuses, bonuses);
          let bots = state.bots.ids;
          bots = bots.map((item) => {
            return {
              id: item,
              checked: round[item] === "Y",
            };
          });
          botsAdapter.upsertMany(state.bots, bots);
        }
      })
      .addCase(getGameInfo.rejected, (state) => {
        state.gameInfo.loading = fetchStatuses.rejected;
      })
      .addCase(listInvites.pending, (state, action) => {
        state.gameInvites.loading = fetchStatuses.pending;
      })
      .addCase(listInvites.fulfilled, (state, action) => {
        state.gameInvites.loading = fetchStatuses.fulfilled;
        gameInvitesAdapter.upsertMany(
          state.gameInvites,
          action.payload.data.map((item) => {
            return { id: item.invite.token, url: item.url, ...item.invite };
          })
        );
      })
      .addCase(getJobs.pending, (state) => {
        state.jobs.loading = fetchStatuses.pending;
      })
      .addCase(getJobs.fulfilled, (state, action) => {
        state.jobs.loading = fetchStatuses.fulfilled;
        jobsAdapter.setAll(state.jobs, action.payload.data);
        jobsAdapter.upsertMany(state.jobs, jobsSortOrder);
      })
      .addCase(getJobs.rejected, (state) => {
        state.jobs.loading = fetchStatuses.rejected;
      })
      .addCase(getBonusesInfo.pending, (state) => {
        state.bonuses.loading = fetchStatuses.pending;
      })
      .addCase(getBonusesInfo.fulfilled, (state, action) => {
        state.bonuses.loading = fetchStatuses.fulfilled;
        const arr = action.payload.data.map((item) => {
          return { ...item, id: camelCase(item.id), checked: false };
        });
        bonusesAdapter.setAll(state.bonuses, arr);
      })
      .addCase(getBonusesInfo.rejected, (state) => {
        state.bonuses.loading = fetchStatuses.rejected;
      })
      .addCase(getGames.pending, (state) => {
        state.games.loading = fetchStatuses.pending;
      })
      .addCase(getGames.fulfilled, (state, action) => {
        state.games.loading = fetchStatuses.fulfilled;
        if (action.payload.data.length !== 0) {
          gamesAdapter.setAll(state.games, camelcaseKeys(action.payload.data));
        }
      })
      .addCase(getGames.rejected, (state) => {
        state.games.loading = fetchStatuses.rejected;
      })
      .addCase(getGameRounds.pending, (state) => {
        state.gameRounds.loading = fetchStatuses.pending;
      })
      .addCase(getGameRounds.fulfilled, (state, action) => {
        state.gameRounds.loading = fetchStatuses.fulfilled;
      })
      .addCase(getGameRounds.rejected, (state) => {
        state.gameRounds.loading = fetchStatuses.rejected;
      })
      .addCase(getGamePlayers.pending, (state) => {
        state.gamePlayers.loading = fetchStatuses.pending;
      })
      .addCase(getGamePlayers.fulfilled, (state, action) => {
        gamePlayersAdapter.upsertMany(
          state.gamePlayers,
          action.payload.data.players
        );
        state.gamePlayers.loading = fetchStatuses.fulfilled;
      })
      .addCase(getGamePlayers.rejected, (state) => {
        state.gamePlayers.loading = fetchStatuses.rejected;
      })
      .addCase(getRoundInfo.pending, (state) => {
        // state.gamePlayers.loading = fetchStatuses.pending;
      })
      .addCase(getRoundInfo.fulfilled, (state, action) => {
        gameRoundsAdapter.upsertOne(state.gameRounds, action.payload.data);
        if (action.payload.data.datetimeEnded !== null) {
          state.activeRoundId = null;
          state.activeRound = null;
          state.selectedRoundId = action.payload.data.id;
        } else {
          if (state.activeRoundId === null) {
            state.activeRound = null;
            return;
          }
          if (state.activeRound === null) {
            state.activeRound = action.payload.data;
            gamePlayersAdapter.upsertMany(
              state.gamePlayers,
              action.payload.data.roundPlayers.map((player) => {
                return { id: player.playerId, jobId: player.jobId };
              })
            );
          } else {
            if (
              action.payload.data.durationSeconds !==
              state.activeRound.durationSeconds
            ) {
              state.activeRound = action.payload.data;
            }
          }
        }
        // state.gamePlayers.loading = fetchStatuses.fulfilled;
      })
      .addCase(getRoundInfo.rejected, (state) => {
        state.activeRound = null;
        // state.gamePlayers.loading = fetchStatuses.rejected;
      })
      .addCase(getRoundReport.fulfilled, (state, action) => {
        if (action.payload.status === "ok") {
          roundReportAdapter.addOne(
            state.roundReport,

            {
              ...action.payload.data,
              financeCalculated: calcFinanceResults({
                roundReport: action.payload.data,
              }),
              gameOrders: calcOrdersResults({
                orders: action.payload.data.gameOrders,
              }),
            }
          );
        }
      })
      .addCase(getGameReport.pending, (state) => {
        state.roundReport.loading = fetchStatuses.pending;
      })
      .addCase(getGameReport.fulfilled, (state, action) => {
        state.roundReport.loading = fetchStatuses.fulfilled;
        if (action.payload.status === "ok") {
          roundReportAdapter.setAll(
            state.roundReport,
            action.payload.data.gameRounds
          );
        }
      })
      .addCase(getGameReport.rejected, (state) => {
        state.roundReport.loading = fetchStatuses.rejected;
      });
  },
});

const { actions, reducer } = factorySlice;

export const {
  setActiveRoundId,
  setActiveRound,
  setSelectedRoundId,
  gamePlayersUpdateOne,
  gamePlayersUpdateMany,
  removeGameData,
  gameRoundsUpdateOne,
  bonusesUpdateOne,
  botsUpdateMany,
  roundProductsAddOne,
  roundHubsAddOne,
  roundHubsProductsAddOne,
  roundTransportsAddOne,
  roundReportAddDefectTvs,
} = actions;

export default reducer;

export const factoryApi = {
  getAppInfo,
  getGameInfo,
  getGames,
  getBonusesInfo,
  getRoundInfo,
  roundProductInfo,
  getGamePlayers,
  getGameRounds,
  getJobs,
  createGame,
  getGameReport,
  getRoundReport,
  listInvites,
};
