import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { useSelector } from "react-redux";
import {
  productsSelectors,
  roundProductsSelectors,
} from "../../../../../../../store/games/factorySelectors";
import { scales, titleFont, tooltip } from "./defaults";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const RoundDefectProductsPercent = () => {
  const selectedRoundId = useSelector((state) => state.factory.selectedRoundId);
  const products = useSelector(productsSelectors.selectAll);
  const roundProducts = useSelector((state) =>
    roundProductsSelectors.selectById(state, selectedRoundId)
  ).products;

  const options = {
    scales: scales,
    plugins: {
      tooltip,
      title: {
        text: "Процент брака по продуктам",
        font: titleFont,
      },
    },
  };

  const values = [];
  const data = {
    labels: products
      .filter((product) => {
        return roundProducts[product.id].totalDefect !== 0;
      })
      .map((product) => {
        values.push(
          (
            (roundProducts[product.id].totalDefect * 100) /
            roundProducts[product.id].total
          ).toFixed(2)
        );
        return product.data.name;
      }),
    datasets: [
      {
        maxBarThickness: 120,
      },
    ],
  };

  data.datasets[0].data = values;

  return <Bar options={options} data={data} />;
};

export default RoundDefectProductsPercent;
