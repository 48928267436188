import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import hubService from "../../../../../services/hubService";
import { factoryAppId } from "../../../../../other/constants";
import { factoryApi } from "../../../../../store/games/factorySlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  gameInvitesSelectors,
  gamePlayersSelectors,
} from "../../../../../store/games/factorySelectors";
import { useState } from "react";

const RegenerateInvites = () => {
  const dispatch = useDispatch();
  const { gameId } = useParams();
  const invites = useSelector(gameInvitesSelectors.selectAll);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const gamePlayers = useSelector(gamePlayersSelectors.selectAll);

  if (invites.length !== 0) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        {error !== null && (
          <Grid item xs={12}>
            {error}
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant={"body1"}>
            Ссылки для игроков устарели, т.к. прошло 2 недели с их создания.
            Создайте новые ссылки и отправьте игрокам, чтобы они могли зайти в
            эту игру.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            size={"small"}
            color={"primary"}
            loading={loading}
            variant={"outlined"}
            onClick={async () => {
              setLoading(true);
              const hubInvitesResponse = await hubService.createInvites({
                appId: factoryAppId,
                tag: gameId,
                invites: gamePlayers.map((gamePlayer) => {
                  return { token: gamePlayer.uuid };
                }),
              });
              const hubResponseData = hubInvitesResponse.data;
              if (hubResponseData.status === "ok") {
                dispatch(
                  factoryApi.listInvites({
                    appId: factoryAppId,
                    tag: gameId,
                  })
                );
              } else {
                setLoading(false);
                setError("Ошибка при создании ссылок для игроков");
              }
            }}
          >
            Сгенерировать ссылки
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RegenerateInvites;
