import React from "react";
import AppsList from "./AppsList";
import Grid from "@mui/material/Grid";

const AppsHub = () => {
  return (
    <Grid container spacing={3}>
      <Grid item />
      <Grid item xs={12}>
        <AppsList />
      </Grid>
    </Grid>
  );
};

export default AppsHub;
