import img01 from "../media/images/products/small/1.png";
import img02 from "../media/images/products/small/2.png";
import img03 from "../media/images/products/small/3.png";
import img04 from "../media/images/products/small/4.png";
import img05 from "../media/images/products/small/5.png";
import img06 from "../media/images/products/small/6.png";
import img07 from "../media/images/products/small/7.png";
import img08 from "../media/images/products/small/8.png";
import img09 from "../media/images/products/small/9.png";
import img10 from "../media/images/products/small/10.png";
import img11 from "../media/images/products/small/11.png";
import img12 from "../media/images/products/small/12.png";
import img13 from "../media/images/products/small/13.png";
import img14 from "../media/images/products/small/14.png";
import img15 from "../media/images/products/small/15.png";
import img16 from "../media/images/products/small/16.png";
import img17 from "../media/images/products/small/17.png";
import img18 from "../media/images/products/small/18.png";
import img19 from "../media/images/products/small/19.png";
import img20 from "../media/images/products/small/20.png";
import img21 from "../media/images/products/small/21.png";
import img22 from "../media/images/products/small/22.png";
import img23 from "../media/images/products/small/23.png";
import img24 from "../media/images/products/small/24.png";

const productNodeXDist = 500;
const productNodeYDist = 160;

export const productNodeWidth = 400;
export const productNodeHeight = 120;

const sectors = {
  1: {
    x: 1,
    y: 1,
  },
  2: {
    x: 2,
    y: 1,
  },
  3: {
    x: 3,
    y: 1,
  },
  4: {
    x: 2,
    y: 2,
  },
  5: {
    x: 3,
    y: 2,
  },
  6: {
    x: 4,
    y: 2,
  },
  7: {
    x: 5,
    y: 2,
  },
  8: {
    x: 1,
    y: 7,
  },
  9: {
    x: 1,
    y: 6,
  },
  10: {
    x: 1,
    y: 8,
  },
  11: {
    x: 2,
    y: 7,
  },
  12: {
    x: 2,
    y: 6,
  },
  13: {
    x: 2,
    y: 8,
  },
  14: {
    x: 3,
    y: 7,
  },
  15: {
    x: 3,
    y: 3,
  },
  16: {
    x: 3,
    y: 4,
  },
  17: {
    x: 3,
    y: 5,
  },
  18: {
    x: 4,
    y: 3,
  },
  19: {
    x: 5,
    y: 1,
  },
  20: {
    x: 4,
    y: 7,
  },
  21: {
    x: 3,
    y: 6,
  },
  22: {
    x: 3,
    y: 8,
  },
  23: {
    x: 5,
    y: 3,
  },
  24: {
    x: 6,
    y: 2,
  },
};

export const productsInitialState = [
  {
    id: "1",
    position: {
      x: 150,
      y: 50,
    },
    type: "productNode",
    data: {
      prevProductsId: [],
      nextProductId: 2,
      name: "Гексафторид урана",
      weight: 500,
    },
  },
  {
    id: "2",
    position: {
      x: 570,
      y: 50,
    },
    type: "productNode",
    data: {
      prevProductsId: [1],
      nextProductId: 3,
      name: "Пресс-порошок",
      weight: 250,
    },
  },
  {
    id: "3",
    position: {
      x: 1000,
      y: 50,
    },
    type: "productNode",
    data: {
      prevProductsId: [2],
      nextProductId: 18,
      name: "Таблетка",
      weight: 2,
    },
  },
  {
    id: "4",
    position: {
      x: 600,
      y: 200,
    },
    type: "productNode",
    data: {
      prevProductsId: [],
      nextProductId: 5,
      name: "Проволока",
      weight: 1,
    },
  },
  {
    id: "5",
    position: {
      x: 1000,
      y: 200,
    },
    type: "productNode",
    data: {
      prevProductsId: [4],
      nextProductId: 18,
      name: "Фиксатор",
      weight: 0,
    },
  },
  {
    id: "6",
    position: {
      x: 1800,
      y: 300,
    },
    type: "productNode",
    data: {
      prevProductsId: [],
      nextProductId: 7,
      name: "Заготовка",
      weight: 5,
    },
  },
  {
    id: "7",
    position: {
      x: 2300,
      y: 300,
    },
    type: "productNode",
    data: {
      prevProductsId: [6],
      nextProductId: 24,
      name: "Концевая деталь",
      weight: 5,
    },
  },
  {
    id: "8",
    position: {
      x: 560,
      y: 1000,
    },
    type: "productNode",
    data: {
      prevProductsId: [],
      nextProductId: 11,
      name: "Труба Ø16",
      weight: 8,
    },
  },
  {
    id: "9",
    position: {
      x: 560,
      y: 1000,
    },
    type: "productNode",
    data: {
      prevProductsId: [],
      nextProductId: 12,
      name: "Труба Ø78",
      weight: 40,
    },
  },
  {
    id: "10",
    position: {
      x: 500,
      y: 1300,
    },
    type: "productNode",
    data: {
      prevProductsId: [],
      nextProductId: 13,
      name: "Труба Ø16,6",
      weight: 8,
    },
  },
  {
    id: "11",
    position: {
      x: 950,
      y: 1050,
    },
    type: "productNode",
    data: {
      prevProductsId: [8],
      nextProductId: 14,
      name: "Дистанционирующая ячейка",
      weight: 0,
    },
  },
  {
    id: "12",
    position: {
      x: 1000,
      y: 850,
    },
    type: "productNode",
    data: {
      prevProductsId: [9],
      nextProductId: 14,
      name: "Обойма",
      weight: 1,
    },
  },
  {
    id: "13",
    position: {
      x: 1000,
      y: 1300,
    },
    type: "productNode",
    data: {
      prevProductsId: [10],
      nextProductId: 14,
      name: "Внутренняя труба",
      weight: 0,
    },
  },
  {
    id: "14",
    position: {
      x: 1800,
      y: 800,
    },
    type: "productNode",
    data: {
      prevProductsId: [11, 12, 13],
      nextProductId: 20,
      name: "Дистанционирующая решётка",
      weight: 1,
    },
  },
  {
    id: "15",
    position: {
      x: 1000,
      y: 400,
    },
    type: "productNode",
    data: {
      prevProductsId: [],
      nextProductId: 18,
      name: "Труба Ø13,58",
      weight: 1,
    },
  },
  {
    id: "16",
    position: {
      x: 1000,
      y: 550,
    },
    type: "productNode",
    data: {
      prevProductsId: [],
      nextProductId: 18,
      name: "Наконечник",
      weight: 0,
    },
  },
  {
    id: "17",
    position: {
      x: 1000,
      y: 700,
    },
    type: "productNode",
    data: {
      prevProductsId: [],
      nextProductId: 18,
      name: "Заглушка",
      weight: 0,
    },
  },
  {
    id: "18",
    position: {
      x: 1800,
      y: 500,
    },
    type: "productNode",
    data: {
      prevProductsId: [3, 5, 15, 16, 17],
      nextProductId: 23,
      name: "ТВЭЛ",
      weight: 3,
    },
  },
  {
    id: "19",
    position: {
      x: 2300,
      y: 150,
    },
    type: "productNode",
    data: {
      prevProductsId: [],
      nextProductId: 24,
      name: "Шестигранная труба",
      weight: 10,
    },
  },
  {
    id: "20",
    position: {
      x: 2300,
      y: 1000,
    },
    type: "productNode",
    data: {
      prevProductsId: [14, 21, 22],
      nextProductId: 23,
      name: "Каркас",
      weight: 6,
    },
  },
  {
    id: "21",
    position: {
      x: 1800,
      y: 1000,
    },
    type: "productNode",
    data: {
      prevProductsId: [],
      nextProductId: 20,
      name: "Концевая решетка для каркаса",
      weight: 1,
    },
  },
  {
    id: "22",
    position: {
      x: 1800,
      y: 1100,
    },
    type: "productNode",
    data: {
      prevProductsId: [],
      nextProductId: 20,
      name: "Каркасная труба",
      weight: 5,
    },
  },
  {
    id: "23",
    position: {
      x: 2300,
      y: 500,
    },
    type: "productNode",
    data: {
      prevProductsId: [18, 20],
      nextProductId: 24,
      name: "Пучок",
      weight: 70,
    },
  },
  {
    id: "24",
    position: {
      x: 3300,
      y: 500,
    },
    type: "productNode",
    data: {
      prevProductsId: [7, 19, 23],
      nextProductId: null,
      name: "ТВС",
      weight: 160,
    },
  },
].map((item) => {
  return {
    ...item,
    position: {
      x: sectors[item.id].x * productNodeXDist,
      y: sectors[item.id].y * productNodeYDist,
    },
  };
});

export const productsImages = {
  1: img01,
  2: img02,
  3: img03,
  4: img04,
  5: img05,
  6: img06,
  7: img07,
  8: img08,
  9: img09,
  10: img10,
  11: img11,
  12: img12,
  13: img13,
  14: img14,
  15: img15,
  16: img16,
  17: img17,
  18: img18,
  19: img19,
  20: img20,
  21: img21,
  22: img22,
  23: img23,
  24: img24,
};
