import React, { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import factoryService from "../../../services/factoryService";
import hubService from "../../../services/hubService";
import { factoryAppId } from "../../../other/constants";
import { useNavigate } from "react-router-dom";

const CreateGameButton = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  return (
    <LoadingButton
      size={"large"}
      variant={"outlined"}
      loading={loading}
      onClick={async () => {
        setLoading(true);
        try {
          const createGameResponse = await factoryService.createGame();
          const data = await createGameResponse.data;
          const gameId = data.data.id;
          const factoryInvitesResponse = await factoryService.createInvites({
            gameId: gameId,
            invites: 10,
          });
          const uuids = factoryInvitesResponse.data.data.uuid.map((item) => {
            return { token: item };
          });

          const hubInvitesResponse = await hubService.createInvites({
            appId: factoryAppId,
            tag: gameId,
            invites: uuids,
          });
          const hubResponseData = hubInvitesResponse.data;
          if (hubResponseData.status === "ok") {
            navigate(`game/${gameId}`);
          }
        } catch (e) {
          setLoading(false);
        }
      }}
    >
      Создать игру
    </LoadingButton>
  );
};

export default CreateGameButton;
