import React from "react";
import Chip from "@mui/material/Chip";

const GamePlayerStatus = ({ datetimeOnlineUnix }) => {
  const seconds = Math.floor(Date.now() / 1000 - datetimeOnlineUnix);
  if (seconds <= 20) {
    return (
      <Chip
        label={"Онлайн"}
        color={"success"}
        size={"small"}
        variant={"outlined"}
      />
    );
  } else if (seconds > 20 && seconds < 40) {
    return (
      <Chip
        label={"Отключился"}
        color={"warning"}
        size={"small"}
        variant={"outlined"}
      />
    );
  } else {
    return (
      <Chip
        label={"Оффлайн"}
        color={"error"}
        size={"small"}
        variant={"outlined"}
      />
    );
  }
};

export default GamePlayerStatus;
