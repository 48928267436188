import React from "react";
import Grid from "@mui/material/Grid";
import Orders from "./Orders/Orders";
import Finance from "./Finance/Finance";
import ProductsFlowProvider from "./ProductsInfo/ProductsFlowProvider";
import ChartsDefaults from "./Charts/ChartsDefaults";
import HubsInfo from "./HubsInfo/HubsInfo";
import InfoCenterDivider from "./Charts/InfocenterDivider";
import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import ChartWrapper from "./Charts/ChartWrapper";
import RoundDefectProductsPercent from "./Charts/RoundDefectProductsPercent";
import DefectPercentByRounds from "./Charts/DefectPercentByRounds";
import TvsByRounds from "./Charts/TvsByRounds";
import NzpByHubs from "./Charts/NzpByHubs";
import CostsPie from "./Charts/CostsPie";

const RoundReport = () => {
  const theme = useTheme();
  return (
    <Grid container spacing={3}>
      <ChartsDefaults />
      <Grid item xs={12}>
        <Finance />
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"h5"}>Инфоцентр</Typography>
      </Grid>
      <InfoCenterDivider
        color={theme.palette.primary.dark}
        text={"Качество"}
        textColor={"white"}
      />
      <Grid item xs={12}>
        <ProductsFlowProvider />
      </Grid>
      <Grid item xs={12} md={12}>
        <ChartWrapper child={<RoundDefectProductsPercent />} />
      </Grid>
      <Grid item xs={12} md={12}>
        <ChartWrapper child={<DefectPercentByRounds />} />
      </Grid>
      <InfoCenterDivider
        color={theme.palette.warning.light}
        text={"Исполнение заказов/ресурсы"}
        textColor={"black"}
      />
      <Grid item xs={12}>
        <Orders />
      </Grid>
      <Grid item xs={12} md={12}>
        <ChartWrapper child={<TvsByRounds />} />
      </Grid>
      <InfoCenterDivider
        color={theme.palette.success.main}
        text={"Затраты"}
        textColor={"white"}
      />
      <Grid item xs={12}>
        <HubsInfo />
      </Grid>
      <Grid item xs={12} md={6}>
        <ChartWrapper child={<NzpByHubs />} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ChartWrapper paddingBottom={"36px"} child={<CostsPie />} />
      </Grid>
    </Grid>
  );
};

export default RoundReport;
