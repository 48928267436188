import React from "react";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import TimerCard from "./Timer/TimerCard";
import PlayersCountCard from "./Players/PlayersCountCard";
import StatusCard from "./Status/StatusCard";

const ControlPanel = () => {
  const activeRound = useSelector((state) => state.factory.activeRound);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant={"h5"}>Информация о раунде</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <StatusCard />
      </Grid>
      {activeRound !== null && (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <TimerCard />
        </Grid>
      )}
      {activeRound !== null && (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <PlayersCountCard />
        </Grid>
      )}
    </Grid>
  );
};

export default ControlPanel;
