import React, { useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import {
  gameInvitesSelectors,
  gamePlayersSelectors,
} from "../../../../../store/games/factorySelectors";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Tooltip from "@mui/material/Tooltip";
import GamePlayer from "./GamePlayer";
import { msToDeleteLocalStorageItem } from "../../../../../other/constants";
import IconButton from "@mui/material/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useState } from "react";

const GamePlayersList = () => {
  const [tooltipText, setTooltipText] = useState("Копировать все ссылки");
  const list = useSelector(gamePlayersSelectors.selectAll);
  const localstorageActualizedRef = useRef(false);
  const invites = useSelector(gameInvitesSelectors.selectAll);

  useEffect(() => {
    if (list.length !== 0 && !localstorageActualizedRef.current) {
      if (localStorage.getItem("playersCommentsDatetimeUsed") === null) {
        localStorage.setItem("playersCommentsDatetimeUsed", JSON.stringify({}));
      }
      const data = JSON.parse(
        localStorage.getItem("playersCommentsDatetimeUsed")
      );
      list.forEach((item) => {
        if (!data.hasOwnProperty(item.id)) {
          localStorage.setItem(`playerId${item.id}comment`, "");
        }
        data[item.id] = Date.now();
      });
      const playersId = Object.keys(data);
      playersId.forEach((playerId) => {
        const timeAdded = Number(data[playerId]);
        if (Date.now() - timeAdded > msToDeleteLocalStorageItem) {
          delete data[playerId];
          localStorage.removeItem(`playerId${playerId}comment`);
        }
      });
      localStorage.setItem("playersCommentsDatetimeUsed", JSON.stringify(data));
      localstorageActualizedRef.current = true;
    }
  }, [list]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: 70 }}>Логин</TableCell>
                <TableCell sx={{ width: 110 }}>Статус</TableCell>
                <TableCell>Комментарий</TableCell>
                <TableCell>Роль</TableCell>
                {invites.length !== 0 && (
                  <TableCell>
                    <Tooltip
                      placement={"top"}
                      title={tooltipText}
                      onOpen={() => {
                        setTooltipText("Копировать все ссылки");
                      }}
                    >
                      <IconButton
                        size={"small"}
                        color={"primary"}
                        onClick={() => {
                          const textToCopy = invites
                            .map((invite) => {
                              return `${invite.id} - ${invite.url}`;
                            })
                            .join("\n");
                          navigator.clipboard.writeText(textToCopy).then(
                            function () {
                              setTooltipText(
                                "Ссылки скопированы в буфер обмена"
                              );
                            },
                            function (err) {
                              setTooltipText(
                                "Async: Could not copy text: " + err
                              );
                            }
                          );
                        }}
                      >
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                )}
                {invites.length !== 0 && <TableCell>Открыть</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((player) => {
                return <GamePlayer key={player.id} player={player} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default GamePlayersList;
