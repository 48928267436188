import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PlayersList from "./PlayersList";

const Players = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant={"h5"}>Игроки завершенного раунда</Typography>
      </Grid>
      <Grid item xs={12}>
        <PlayersList />
      </Grid>
    </Grid>
  );
};

export default Players;
