export const scales = {
  x: {
    ticks: {
      color: "black",
      font: {
        size: 14,
      },
    },
  },
};

export const tooltip = {
  enabled: true,
  titleFont: {
    size: 18,
    weight: 500,
  },
  bodyFont: {
    size: 14,
    weight: 400,
  },
};

export const titleFont = {
  size: 18,
  weight: 500,
};
