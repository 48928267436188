import { createSlice } from "@reduxjs/toolkit";
import { fetchStatuses } from "../other/constants";

export const hubSlice = createSlice({
  name: "hub",
  initialState: {
    apps: [],
    dataLoading: fetchStatuses.idle,
  },
  reducers: {
    setApps: (state, action) => {
      state.apps = action.payload.apps;
      state.dataLoading = fetchStatuses.fulfilled;
    },
    resetHubStore: (state) => {
      state.apps = [];
      state.dataLoading = fetchStatuses.idle;
    },
  },
  extraReducers: (builder) => {},
});

export const { setApps, resetHubStore } = hubSlice.actions;

export default hubSlice.reducer;

export const hubApi = {};
