import React from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getTimeString } from "../../../../../../../other/utils";
import { useForceRender } from "../../../../../../../other/hooks";
import { LinearProgress } from "@mui/material";

const CurrentRoundTime = () => {
  const activeRound = useSelector((state) => state.factory.activeRound);
  const roundEndTime = getTimeString(activeRound.durationSeconds);
  const forceRerender = useForceRender();
  const datetimeStarted = useSelector(
    (state) => state.factory.activeRound
  ).datetimeStarted;

  useEffect(() => {
    const timer = setInterval(() => {
      forceRerender();
    }, 1000);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const currentSeconds = Date.now() / 1000 - datetimeStarted;
  const roundCurrentTime = getTimeString(
    //Если секунды раунда меньше 0 или больше чем продолжительность раунда, то изменяем секунды раунда, чтобы они не были больше или меньше
    currentSeconds < 0
      ? 0
      : currentSeconds > activeRound.durationSeconds
      ? activeRound.durationSeconds
      : currentSeconds
  );

  return (
    <>
      {roundCurrentTime} / {roundEndTime}
      {currentSeconds >= activeRound.durationSeconds && (
        <LinearProgress size={"small"} />
      )}
    </>
  );
};

export default CurrentRoundTime;
