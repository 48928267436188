import React, { useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useSelector } from "react-redux";
import { jobsSelectors } from "../../../../../store/games/factorySelectors";
import { TextField } from "@mui/material";

const Player = ({ player }) => {
  const job = useSelector((state) =>
    jobsSelectors.selectById(state, player.jobId)
  );
  const [comment] = useState(() => {
    const data = localStorage.getItem(`playerId${player.id}comment`);
    if (data === null) {
      return "";
    }
    return data.replaceAll('"', "");
  });

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell>{player.id}</TableCell>
      <TableCell>
        <TextField
          variant={"standard"}
          fullWidth
          size={"small"}
          value={comment}
          disabled
        />
      </TableCell>
      <TableCell>{job === undefined ? "-" : job.name}</TableCell>
    </TableRow>
  );
};

export default Player;
