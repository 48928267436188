import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

const FilterByDate = ({ currCriteria, handleNewCriteria }) => {
  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant={"body1"}>Показать рейтинг за период:</Typography>
        </Grid>
        <Grid item>
          <DatePicker
            value={currCriteria["where[ended_at_from]"]}
            label="с"
            disableFuture={true}
            minDate={moment("2023")}
            maxDate={currCriteria["where[ended_at_to]"]}
            onChange={(newValue, context) => {
              if (context.validationError === null) {
                handleNewCriteria("where[ended_at_from]", newValue);
              }
            }}
          />
        </Grid>
        <Grid item>
          <DatePicker
            sx={{ ml: 2 }}
            value={currCriteria["where[ended_at_to]"]}
            label="по"
            disableFuture={true}
            minDate={currCriteria["where[ended_at_from]"]}
            maxDate={moment("2042")}
            onChange={(newValue, context) => {
              if (context.validationError === null) {
                handleNewCriteria("where[ended_at_to]", newValue);
              }
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FilterByDate;
