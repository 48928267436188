import React from "react";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

const Placement = ({ number }) => {
  if (number === 1) {
    return <EmojiEventsIcon sx={{ color: "#ffd700" }} />;
  }
  if (number === 2) {
    return <EmojiEventsIcon sx={{ color: "#C0C0C0" }} />;
  }
  if (number === 3) {
    return <EmojiEventsIcon sx={{ color: "#CD7F32" }} />;
  }
  return number;
};

export default Placement;
