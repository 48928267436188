import React from "react";
import Box from "@mui/material/Box";
import { LinearProgress } from "@mui/material";
import { useEffect } from "react";
import hubService from "../../services/hubService";
import { setApps } from "../../store/hubSlice";
import { setAuthStatus, setFactoryUser } from "../../store/userSlice";
import { fetchStatuses } from "../../other/constants";
import { useDispatch } from "react-redux";

const LoadHubData = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      const response = await hubService.getApps();
      const data = response.data;
      data.data.forEach((app) => {
        if (app.id === 1) {
          dispatch(setFactoryUser({ user: app.data }));
          dispatch(setAuthStatus({ authStatus: fetchStatuses.fulfilled }));
        }
      });
      dispatch(
        setApps({
          apps: data.data.map((app) => {
            return { id: app.id, name: app.name };
          }),
        })
      );
    })();
  }, [dispatch]);
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        backgroundColor: "rgb(231, 235, 240)",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "90%" }}>
        Загружаю игры...
        <LinearProgress />
      </Box>
    </Box>
  );
};

export default LoadHubData;
