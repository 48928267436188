import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../services/authService";
import { fetchStatuses } from "../other/constants";
import { getCookie } from "../other/utils";

const identification = createAsyncThunk(
  "user/identification",
  async ({ uuid }) => {
    const response = await authService.identification(uuid);
    return response.data;
  }
);

const registration = createAsyncThunk(
  "user/register",
  async ({ email, password }) => {
    const response = await authService.registration({ email, password });
    return response.data;
  }
);

const authorization = createAsyncThunk(
  "user/authorization",
  async ({ email, password }) => {
    const response = await authService.authorization({ email, password });
    return response.data;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    factory: null,
    user: "a.kostyan@elearnlab.ru",
    authError: null,
    authStatus:
      getCookie("token") === undefined
        ? fetchStatuses.idle
        : fetchStatuses.fulfilled,
  },
  reducers: {
    setAuthStatus: (state, action) => {
      state.authStatus = action.payload.authStatus;
    },
    setFactoryUser: (state, action) => {
      state.factory = action.payload.user;
    },
    resetUserStore: (state) => {
      document.cookie =
        "token=; max-age=-1; path=/; secure=true; samesite=strict;";
      state.factory = null;
      state.authError = null;
      state.authStatus = fetchStatuses.idle;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(identification.pending, (state) => {})
      .addCase(identification.fulfilled, (state, action) => {})
      .addCase(identification.rejected, (state) => {})
      .addCase(authorization.pending, (state) => {
        state.authStatus = fetchStatuses.pending;
      })
      .addCase(authorization.fulfilled, (state, action) => {
        const auth = action.payload.data.authorization;
        document.cookie = `token=${auth};  path=/; secure=true; samesite=strict;`;
        state.authStatus = fetchStatuses.fulfilled;
        state.auth = auth;
      })
      .addCase(authorization.rejected, (state, action) => {
        state.authStatus = fetchStatuses.rejected;
        if (action.error.code === "ERR_BAD_REQUEST") {
          state.authError = "Неверный логин или пароль";
        } else {
          state.authError = "Непредвиденная ошибка";
        }
      });
  },
});

export const { setAuthStatus, setFactoryUser, resetUserStore } =
  userSlice.actions;

export default userSlice.reducer;

export const userApi = {
  authorization,
  registration,
};
