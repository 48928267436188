import { Handle, Position } from "reactflow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "./ProductNode.css";
import { useTheme } from "@mui/material";
import {
  productNodeHeight,
  productNodeWidth,
  productsImages,
} from "../../../../../../../other/productsConstants";

const ProductNode = (data) => {
  const theme = useTheme();
  return (
    <>
      <Handle type="target" position={Position.Left} isConnectable={false} />
      <Box
        component={Paper}
        elevation={12}
        sx={{
          width: `${productNodeWidth}px`,
          height: `${productNodeHeight}px`,
          display: "flex",
          gap: theme.spacing(1),
          padding: theme.spacing(1),
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={productsImages[data.id]}
            width={"96px"}
            height={"96px"}
            alt={"nodeProductImage"}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant={"body1"} sx={{ fontWeight: 700 }}>
            {data.data.name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <div
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "4px",
                backgroundColor: theme.palette.primary.light,
              }}
            />
            <Typography variant={"body2"}>Всего:</Typography>
            <Typography variant={"body2"} fontWeight={600}>
              {data.data.total}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <div
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "4px",
                backgroundColor: theme.palette.warning.light,
              }}
            />
            <Typography variant={"body2"}>Брак:</Typography>
            <Typography variant={"body2"} fontWeight={600}>
              {data.data.totalDefect}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <div
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "4px",
                backgroundColor: theme.palette.warning.dark,
              }}
            />
            <Typography variant={"body2"}>Брак использован:</Typography>
            <Typography variant={"body2"} fontWeight={600}>
              {data.data.usedDefect}
            </Typography>
          </Box>
        </Box>

        {/*<Typography variant={"caption"}>*/}
        {/*  Всего: Брак: без проверки качества: {data.data.defectWithoutControl}*/}
        {/*</Typography>*/}
        {/*<Typography variant={"caption"}>*/}
        {/*  Использовано: {data.data.used}*/}
        {/*</Typography>*/}
        {/*<Typography variant={"caption"}>*/}
        {/*  Ошибка при проверке Брак:а: {data.data.defectControlFail}*/}
        {/*</Typography>*/}
        {/*<Typography variant={"caption"}>*/}
        {/*  Ошибка при проверке целой: {data.data.nonDefectControlFail}*/}
        {/*</Typography>*/}
      </Box>
      <Handle type="source" position={Position.Right} isConnectable={false} />
    </>
  );
};

export default ProductNode;
