import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { botsUpdateMany } from "../../../../../../store/games/factorySlice";
import { jobsSelectors } from "../../../../../../store/games/factorySelectors";

const Bot = ({ bot }) => {
  const dispatch = useDispatch();
  const activeRoundId = useSelector((state) => state.factory.activeRoundId);
  const jobId = bot.id.replace("isBotJob", "");
  const jobName = useSelector((state) =>
    jobsSelectors.selectById(state, jobId)
  ).name;

  return (
    <Grid item xs={12} md={6} lg={4} xl={3}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              disabled={activeRoundId !== null || bot.disabled}
              checked={bot.checked}
              size={"small"}
              onClick={(e) => {
                const checked = e.target.checked;
                dispatch(
                  botsUpdateMany([
                    {
                      id: "isBotJob1",
                      changes: { checked },
                    },
                    {
                      id: "isBotJob2",
                      changes: { checked },
                    },
                    {
                      id: "isBotJob3",
                      changes: { checked },
                    },
                    {
                      id: "isBotJob4",
                      changes: { checked },
                    },
                    {
                      id: "isBotJob6",
                      changes: { checked },
                    },
                    {
                      id: "isBotJob7",
                      changes: { checked },
                    },
                    {
                      id: "isBotJob9",
                      changes: { checked },
                    },
                  ])
                );
              }}
            />
          }
          label={jobName}
        />
      </FormGroup>
    </Grid>
  );
};

export default Bot;
