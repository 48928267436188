import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { factoryApi, removeGameData } from "../../../store/games/factorySlice";
import {
  factoryAppId,
  fetchStatuses,
  gamePlayersUpdateInterval,
} from "../../../other/constants";
import Grid from "@mui/material/Grid";
import { LinearProgress, Typography } from "@mui/material";
import Game from "./Game";

const GameWrapper = () => {
  const dispatch = useDispatch();
  const { gameId } = useParams();
  const appInfoLoading = useSelector((state) => state.factory.appInfoLoading);
  const gameInfoLoading = useSelector(
    (state) => state.factory.gameInfo.loading
  );

  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    let interval = null;
    let gamePlayersPromise = null;
    const getPlayers = () => {
      gamePlayersPromise = dispatch(
        factoryApi.getGamePlayers({ gameId: gameId })
      );
    };
    const getAppInfo =
      appInfoLoading === fetchStatuses.idle
        ? dispatch(factoryApi.getAppInfo())
        : null;
    const getGameInfo = dispatch(factoryApi.getGameInfo({ gameId }));
    const listInvites = dispatch(
      factoryApi.listInvites({ appId: factoryAppId, tag: gameId })
    );

    Promise.all([getAppInfo, getGameInfo, listInvites]).then(() => {
      setDataFetched(true);
      interval = setInterval(getPlayers, gamePlayersUpdateInterval);
    });
    return () => {
      if (interval !== null) {
        clearInterval(interval);
      }
      if (gamePlayersPromise !== null) {
        gamePlayersPromise.abort();
      }
      if (getGameInfo !== null) {
        getGameInfo.abort();
      }
      if (getAppInfo !== null) {
        getAppInfo.abort();
      }
      if (listInvites !== null) {
        listInvites.abort();
      }
      dispatch(removeGameData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    appInfoLoading === fetchStatuses.rejected ||
    gameInfoLoading === fetchStatuses.rejected
  ) {
    return (
      <Grid container spacing={3}>
        <Grid item />
        <Grid item xs={12}>
          <Typography variant={"h6"}>
            Произошла ошибка при загрузке данных игры, обновите страницу
          </Typography>
        </Grid>
      </Grid>
    );
  }

  if (!dataFetched) {
    return (
      <Grid container spacing={3}>
        <Grid item />
        <Grid item xs={12}>
          <Typography variant={"h6"}>Загрузка данных игры...</Typography>
        </Grid>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      </Grid>
    );
  }

  return <Game />;
};

export default GameWrapper;
