import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import factoryService from "../../../../services/factoryService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Placement from "./Placement";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import { LinearProgress } from "@mui/material";
import { fetchStatuses } from "../../../../other/constants";
import Typography from "@mui/material/Typography";
import Filters from "./Filters";
import moment from "moment";

const initialCriteria = {
  "orderBy[id]": null,
  "orderBy[ended_at]": null,
  "orderBy[result_profit]": null,
  "where[ended_at_from]": moment().dayOfYear(1),
  "where[ended_at_to]": moment(),
  "where[duration_seconds_from]": 1800,
  "where[duration_seconds_to]": 2400,
  "pagination[offset]": 0,
  "pagination[limit]": 20,
};

const Leaderboard = () => {
  const [rounds, setRounds] = useState([]);
  const [count, setCount] = useState(0);
  const [currCriteria, setCurrCriteria] = useState(initialCriteria);
  const [newCriteria, setNewCriteria] = useState(initialCriteria);
  const [loadStatus, setLoadStatus] = useState(fetchStatuses.idle);

  const handleNewCriteria = (key, value) => {
    setNewCriteria((newCriteria) => {
      return { ...newCriteria, [key]: value };
    });
  };

  useEffect(() => {
    (async () => {
      setLoadStatus(fetchStatuses.pending);
      try {
        const response = await factoryService.getLeaderboard(newCriteria);
        if (response.data.status === "ok") {
          setRounds(response.data.data.rounds);
          setCount(response.data.count);
          setLoadStatus(fetchStatuses.fulfilled);
          setCurrCriteria(newCriteria);
        } else {
          setLoadStatus(fetchStatuses.rejected);
        }
      } catch (e) {
        setLoadStatus(fetchStatuses.rejected);
      }
    })();
  }, [newCriteria]);

  if (loadStatus === fetchStatuses.rejected) {
    return (
      <Typography>Ошибка при загрузке списка, обновите страницу</Typography>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item />
      <Filters
        currCriteria={currCriteria}
        handleNewCriteria={handleNewCriteria}
      />
      <Grid item xs={12}>
        <Typography variant={"h6"}>Таблица лидеров</Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          {loadStatus === fetchStatuses.pending && <LinearProgress />}
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Место</TableCell>
                <TableCell>id игры</TableCell>
                <TableCell>Дата</TableCell>
                <TableCell>Номер раунда</TableCell>
                <TableCell>Время раунда</TableCell>
                <TableCell>Прибыль</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rounds.map((round, index) => (
                <TableRow key={round.id}>
                  <TableCell>
                    <Placement
                      number={index + 1 + currCriteria["pagination[offset]"]}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {round.game.id}
                  </TableCell>
                  <TableCell>
                    {new Date(round.datetimeEnded * 1000).toLocaleDateString()}
                  </TableCell>
                  <TableCell>{round.number}</TableCell>
                  <TableCell>{round.durationSeconds / 60}</TableCell>
                  <TableCell>{round.resultProfit}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[20, 50]}
                  colSpan={6}
                  count={count}
                  rowsPerPage={currCriteria["pagination[limit]"]}
                  page={
                    currCriteria["pagination[offset]"] /
                    currCriteria["pagination[limit]"]
                  }
                  labelRowsPerPage={"Результатов на странице:"}
                  labelDisplayedRows={({ from, to, count }) => {
                    return `Результаты ${from}–${to} из ${count}`;
                  }}
                  onPageChange={(event, newPage) => {
                    if (loadStatus === fetchStatuses.pending) {
                      return;
                    }
                    handleNewCriteria(
                      "pagination[offset]",
                      newPage * currCriteria["pagination[limit]"]
                    );
                  }}
                  onRowsPerPageChange={(e) => {
                    if (loadStatus === fetchStatuses.pending) {
                      return;
                    }
                    handleNewCriteria("pagination[limit]", e.target.value);
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
          {loadStatus === fetchStatuses.pending && <LinearProgress />}
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default Leaderboard;
