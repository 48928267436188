import axios from "axios";
import camelcaseKeys from "camelcase-keys";
import { resetHubStore } from "../store/hubSlice";
import { getCookie } from "../other/utils";
import { resetUserStore } from "../store/userSlice";

export let API_URL =
  process.env.NODE_ENV === "production"
    ? "https://gameapi.vrfabrika.ru/api/v1/"
    : "https://rosatomnew.s6o.ru/api/v1/";

export let HUB_URL =
  process.env.NODE_ENV === "production"
    ? "https://hubapi.vrfabrika.ru/api/v1/"
    : "https://rosatomhub.s6o.ru/api/v1/";

// API_URL = "https://rosatomnew.s6o.ru/api/v1/";

// const HUB_URL = "https://rosatomhub.s6o.ru/api/v1/";

const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

export const $hubApi = axios.create({
  withCredentials: true,
  baseURL: HUB_URL,
});

let store;

export const injectStore = (_store) => {
  store = _store;
};

$api.interceptors.request.use((config) => {
  const factory = store.getState().user.factory;
  config.headers["user-id"] = factory.user.id;
  config.headers["user-token"] = factory.user.token;
  return config;
});

$api.interceptors.response.use((response) => {
  response.data = camelcaseKeys(response.data, { deep: true });
  return response;
});

$hubApi.interceptors.request.use((config) => {
  config.headers["email"] = store.getState().user.user;
  config.headers["token"] = getCookie("token");
  return config;
});

$hubApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 403) {
      document.cookie =
        "token=; max-age=-1; path=/; secure=true; samesite=strict;";
      store.dispatch(resetUserStore());
      store.dispatch(resetHubStore());
    }
  }
);

export default $api;
