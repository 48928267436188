import React, { useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import GamePlayerStatus from "./GamePlayerStatus";
import GamePlayerJobSelector from "./GamePlayerJobSelector";
import { gameInvitesSelectors } from "../../../../../store/games/factorySelectors";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const GamePlayer = ({ player }) => {
  const [tooltipText, setTooltipText] = useState("Копировать");
  const [comment, setComment] = useState(() => {
    const data = localStorage.getItem(`playerId${player.id}comment`);
    if (data === null) {
      return "";
    }
    return data.replaceAll('"', "");
  });
  const invite = useSelector((state) =>
    gameInvitesSelectors.selectById(state, player.uuid)
  );

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell>{player.uuid}</TableCell>
      <TableCell>
        <GamePlayerStatus datetimeOnlineUnix={player.datetimeOnlineUnix} />
      </TableCell>

      <TableCell>
        <TextField
          variant={"standard"}
          fullWidth
          size={"small"}
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
            localStorage.setItem(
              `playerId${player.id}comment`,
              JSON.stringify(e.target.value)
            );
          }}
        />
      </TableCell>
      <TableCell>
        <GamePlayerJobSelector player={player} />
      </TableCell>
      {invite !== undefined && (
        <TableCell>
          <Tooltip
            placement={"top"}
            title={tooltipText}
            onOpen={() => {
              setTooltipText("Копировать");
            }}
          >
            <IconButton
              color={"primary"}
              size={"small"}
              onClick={() => {
                navigator.clipboard.writeText(invite.url).then(
                  function () {
                    setTooltipText("Ссылка скопирована в буфер обмена");
                  },
                  function (err) {
                    setTooltipText("Async: Could not copy text: " + err);
                  }
                );
              }}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      )}
      {invite !== undefined && (
        <TableCell
          sx={{
            width: "100px",
          }}
        >
          <IconButton
            color={"primary"}
            size={"small"}
            onClick={() => {
              Object.assign(document.createElement("a"), {
                target: "_blank",
                rel: "noopener noreferrer",
                href: invite.url,
              }).click();
            }}
          >
            <OpenInNewIcon />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
};

export default GamePlayer;
