import React from "react";
import GamesList from "./GamesList";
import Grid from "@mui/material/Grid";
import CreateGameButton from "./CreateGameButton";

const Factory = () => {
  return (
    <Grid container spacing={3}>
      <Grid item />
      <Grid item xs={12}>
        <CreateGameButton />
      </Grid>
      <Grid item xs={12}>
        <GamesList />
      </Grid>
    </Grid>
  );
};

export default Factory;
