export const fetchStatuses = {
  idle: "idle",
  pending: "pending",
  fulfilled: "fulfilled",
  rejected: "rejected",
};

export const maxAppWidth = "lg";

export const gameRoundUpdateInterval = 10000;
export const gamePlayersUpdateInterval = 10000;
export const msToDeleteLocalStorageItem = 2592000000; //30 days
export const botsInitialState = [
  {
    id: "isBotJob1",
    description: "",
    checked: false,
    disabled: false,
    sortOrder: 1,
  },
  {
    id: "isBotJob2",
    description: "",
    checked: false,
    disabled: false,
    sortOrder: 2,
  },
  {
    id: "isBotJob3",
    description: "",
    checked: false,
    disabled: false,
    sortOrder: 3,
  },
  {
    id: "isBotJob4",
    description: "",
    checked: false,
    disabled: false,
    sortOrder: 6,
  },
  {
    id: "isBotJob5",
    description: "",
    checked: false,
    disabled: true,
    sortOrder: 7,
  },
  {
    id: "isBotJob6",
    description: "",
    checked: false,
    disabled: false,
    sortOrder: 8,
  },
  {
    id: "isBotJob7",
    description: "",
    checked: false,
    disabled: false,
    sortOrder: 9,
  },
  {
    id: "isBotJob8",
    description: "",
    checked: false,
    disabled: true,
    sortOrder: 10,
  },
  {
    id: "isBotJob9",
    description: "",
    checked: false,
    disabled: false,
    sortOrder: 4,
  },
  {
    id: "isBotJob10",
    description: "",
    checked: false,
    disabled: true,
    sortOrder: 5,
  },
];

export const jobsSortOrder = [
  {
    id: 1,
    sortOrder: 1,
  },
  {
    id: 2,
    sortOrder: 2,
  },
  {
    id: 3,
    sortOrder: 3,
  },
  {
    id: 4,
    sortOrder: 6,
  },
  {
    id: 5,
    sortOrder: 7,
  },
  {
    id: 6,
    sortOrder: 8,
  },
  {
    id: 7,
    sortOrder: 9,
  },
  {
    id: 8,
    sortOrder: 10,
  },
  {
    id: 9,
    sortOrder: 4,
  },
  {
    id: 10,
    sortOrder: 5,
  },
];

export const factoryAppId = 1;
