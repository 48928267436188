import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";

const minDistance = 3;

function valuetext(value) {
  return `${value} минут`;
}

const FilterByRoundTime = ({ currCriteria, handleNewCriteria }) => {
  const [value, setValue] = useState([
    currCriteria["where[duration_seconds_from]"] / 60,
    currCriteria["where[duration_seconds_to]"] / 60,
  ]);

  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
    } else {
      setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
    }
  };
  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant={"body1"}>Фильтр по времени раунда:</Typography>
        </Grid>
        <Grid item xs={12}>
          <Slider
            getAriaLabel={() => "Продолжительность"}
            value={value}
            max={120}
            min={10}
            onChange={handleChange}
            onChangeCommitted={(event, newValue) => {
              handleNewCriteria(
                "where[duration_seconds_from]",
                newValue[0] * 60
              );
              handleNewCriteria("where[duration_seconds_to]", newValue[1] * 60);
            }}
            valueLabelDisplay="auto"
            disableSwap
            valueLabelFormat={valuetext}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FilterByRoundTime;
