import { $hubApi } from "../http/index";

export default class hubService {
  static async getApps() {
    return $hubApi.get(`/apps`);
  }
  static async createInvites({ appId, invites, tag }) {
    return $hubApi.post(`/app/${appId}/invites`, { invites, tag });
  }
  static async listInvites({ appId, tag }) {
    return $hubApi.post(`/app/${appId}/listInvites`, { tag });
  }
}
