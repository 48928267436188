import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { bonusesUpdateOne } from "../../../../../../store/games/factorySlice";

const Bonus = ({ bonus }) => {
  const dispatch = useDispatch();
  const activeRoundId = useSelector((state) => state.factory.activeRoundId);

  return (
    <Grid item xs={12} md={6} lg={4} xl={3}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              disabled={activeRoundId !== null}
              checked={bonus.checked}
              size={"small"}
              onClick={(e) => {
                const checked = e.target.checked;
                dispatch(
                  bonusesUpdateOne({
                    id: bonus.id,
                    changes: { checked },
                  })
                );
              }}
            />
          }
          label={bonus.description}
        />
      </FormGroup>
    </Grid>
  );
};

export default Bonus;
