import { useEffect } from "react";
import { Chart as ChartJS } from "chart.js";
import { useTheme } from "@mui/material";
import color from "color";

const ChartsDefaults = () => {
  const theme = useTheme();

  useEffect(() => {
    const mainColor = color(theme.palette.primary.main).alpha(0.7).string();

    const mainLightColor = color(theme.palette.primary.light)
      .alpha(0.7)
      .string();

    ChartJS.defaults.responsive = true;
    ChartJS.defaults.maintainAspectRatio = false;
    ChartJS.defaults.interaction.intersect = false;
    ChartJS.defaults.plugins.legend.display = false;
    ChartJS.defaults.plugins.tooltip.enabled = true;
    ChartJS.defaults.plugins.title.display = true;
    ChartJS.defaults.plugins.title.color = "black";

    ChartJS.defaults.elements.line.tension = 0.3;
    ChartJS.defaults.elements.line.borderWidth = 2;
    ChartJS.defaults.elements.line.borderColor = mainColor;
    ChartJS.defaults.elements.line.backgroundColor = mainLightColor;

    ChartJS.defaults.elements.point.borderColor = mainColor;
    ChartJS.defaults.elements.point.backgroundColor = mainLightColor;

    ChartJS.defaults.elements.bar.borderColor = mainColor;
    ChartJS.defaults.elements.bar.borderWidth = 2;
    ChartJS.defaults.elements.bar.backgroundColor = mainLightColor;

    ChartJS.defaults.elements.arc.borderColor = mainColor;
    ChartJS.defaults.elements.arc.backgroundColor = mainLightColor;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default ChartsDefaults;
