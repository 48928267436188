import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";

const Bonus = ({ label, checked }) => {
  return (
    <Grid item xs={12} md={6} lg={4} xl={3}>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={checked} size={"small"} />}
          label={label}
        />
      </FormGroup>
    </Grid>
  );
};

export default Bonus;
