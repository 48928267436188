import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { useForceRender } from "../../../../../../../other/hooks";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import factoryService from "../../../../../../../services/factoryService";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { setActiveRound } from "../../../../../../../store/games/factorySlice";

const EndRoundMinutesController = ({ setShowController }) => {
  const dispatch = useDispatch();
  const activeRound = useSelector((state) => state.factory.activeRound);
  const { gameId } = useParams();
  const forceRerender = useForceRender();
  const sliderRef = useRef(null);
  const datetimeStarted = useSelector(
    (state) => state.factory.activeRound
  ).datetimeStarted;
  const roundDurationMinutes =
    useSelector((state) => state.factory.activeRound).durationSeconds / 60;

  const [newEndRoundMinutes, setNewEndRoundMinutes] = useState(() => {
    return roundDurationMinutes;
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const timer = setInterval(() => {
      forceRerender();
    }, 1000);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const roundCurrentMinute = Math.ceil(
    (Date.now() / 1000 - datetimeStarted) / 60
  );
  if (roundCurrentMinute > newEndRoundMinutes) {
    setNewEndRoundMinutes(roundCurrentMinute);
  }
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "15px",
      }}
    >
      {error !== null && <Typography>{error}</Typography>}
      <Slider
        onChange={(event) => {
          setNewEndRoundMinutes(event.target.value);
        }}
        value={newEndRoundMinutes}
        ref={sliderRef}
        max={120}
        min={roundCurrentMinute + 1}
        valueLabelDisplay="auto"
        size={"small"}
      />
      <LoadingButton
        size={"small"}
        loading={loading}
        onClick={async () => {
          try {
            setLoading(true);
            const response = await factoryService.setEndRoundMinutes({
              gameId: gameId,
              minutes: Number(newEndRoundMinutes),
            });
            if (response.data.status === "ok") {
              dispatch(
                setActiveRound({
                  activeRound: {
                    ...activeRound,
                    durationSeconds: newEndRoundMinutes * 60,
                  },
                })
              );
              setError(null);
              setShowController(false);
            } else {
              setError(
                "Не удалось изменить время, обновите страницу и попробуйте снова"
              );
            }
            setLoading(false);
          } catch (e) {
            setLoading(false);
            setError(
              "Не удалось изменить время. " + e.response.data.errors.join(" ")
            );
          }
        }}
      >
        <SaveIcon />
      </LoadingButton>
    </Box>
  );
};

export default EndRoundMinutesController;
