import { getTimeString } from "../../other/utils";

export const calcFinanceResults = ({ roundReport }) => {
  const results = roundReport.gameFinans.reduce(
    (obj, item) => {
      switch (item.comment) {
        case "Закупка продукции":
          obj.suppliesCosts += item.amount;
          break;
        case "Возврат продукции":
          obj.suppliesCosts += item.amount;
          break;
        case "Выручка с заказа":
          obj.revenue += item.amount;
          break;
        case "Оптимизационные мероприятия":
          obj.bonusesCosts = item.amount;
          break;
        case "Сумма ФОТ активных ролей":
          obj.wages = item.amount;
          break;
        case "Аренда центрального склада":
          obj.centralHubRent = item.amount;
          break;
        case "Сумма аренд всех цехов":
          obj.hubsRent = item.amount;
          break;
        case "ОХР":
          obj.generalRunningCosts = item.amount;
          break;
        default: {
        }
      }
      return obj;
    },
    {
      centralHubRent: 0,
      hubsRent: 0,
      wages: 0,
      generalRunningCosts: 0,
      suppliesCosts: 0,
      revenue: 0,
      bonusesCosts: 0,
      marginalIncome: 0,
      fixedCosts: 0,
      netProfit: 0,
      resultNzpFine: 0,
    }
  );
  results.marginalIncome =
    results.revenue + results.suppliesCosts + roundReport.resultNzpFine;
  results.fixedCosts =
    results.wages +
    results.generalRunningCosts +
    results.centralHubRent +
    results.hubsRent +
    results.bonusesCosts;
  results.netProfit = results.marginalIncome + results.fixedCosts;
  results.resultNzpFine = roundReport.resultNzpFine;

  return results;
};

export const calcOrdersResults = ({ orders }) => {
  const results = orders.map((item) => {
    const order = {
      ...item,
      executionDelay: 0,
      defectTvs: 0,
    };
    if (item.datetimeExecution > item.datetimeExecutionPlan) {
      order.executionDelay = getTimeString(
        item.datetimeExecution - item.datetimeExecutionPlan
      );
    }
    return order;
  });

  return results.sort((a, b) => {
    return a.number - b.number;
  });
};
