import React from "react";
import { ReactFlowProvider } from "reactflow";
import ProductsFlow from "./ProductsFlow";

const ProductsFlowProvider = () => {
  return (
    <ReactFlowProvider>
      <ProductsFlow />
    </ReactFlowProvider>
  );
};

export default ProductsFlowProvider;
