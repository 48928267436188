import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import logo from "../media/images/logo_Academy_rus_goriz.png";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import { maxAppWidth } from "../other/constants";

const Header = () => {
  const theme = useTheme();
  return (
    <>
      <Toolbar />
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position={"fixed"}
          color={"transparent"}
          sx={{
            backdropFilter: "blur(8px)",
          }}
        >
          <Container maxWidth={maxAppWidth}>
            <Toolbar disableGutters>
              <Link to={"/app/factory"}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mr: 6,
                  }}
                >
                  <img src={logo} height={60} alt={"logo"} />
                </Box>
              </Link>
              <Box sx={{ display: "flex", gap: 2 }}>
                {/*<Link*/}
                {/*  to={"/app/factory"}*/}
                {/*  style={{*/}
                {/*    textDecoration: "none",*/}
                {/*  }}*/}
                {/*>*/}
                {/*  <Typography*/}
                {/*    variant="button"*/}
                {/*    noWrap*/}
                {/*    color={theme.palette.primary.main}*/}
                {/*  >*/}
                {/*    Фабрика процессов*/}
                {/*  </Typography>*/}
                {/*</Link>*/}
                <Link
                  to={"/app/factory/leaderboard"}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    variant="button"
                    noWrap
                    color={theme.palette.primary.main}
                  >
                    Таблица лидеров
                  </Typography>
                </Link>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
    </>
  );
};

export default Header;
