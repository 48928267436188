import React from "react";
import { TextField, useTheme } from "@mui/material";
import { userApi } from "../../store/userSlice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import logo from "../../media/images/logo_Academy_rus_goriz.png";
import LoadingButton from "@mui/lab/LoadingButton";
import { fetchStatuses } from "../../other/constants";
import Typography from "@mui/material/Typography";

const Auth = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [email, setEmail] = useState("a.kostyan@elearnlab.ru");
  const [password, setPassword] = useState("");
  const authStatus = useSelector((state) => state.user.authStatus);
  const authError = useSelector((state) => state.user.authError);

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        backgroundColor: "rgb(231, 235, 240)",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box component={Paper}>
        <Box
          component={"form"}
          onSubmit={(e) => {
            e.preventDefault();
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "12px",
            padding: "24px",
          }}
          noValidate
        >
          <img src={logo} style={{ width: "320px" }} alt={"logo"} />
          <TextField
            sx={{
              display: "none",
            }}
            label={"email"}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <TextField
            label={"Пароль"}
            id={"password"}
            value={password}
            fullWidth
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            type={"password"}
          />
          {authError !== null && authStatus !== fetchStatuses.pending && (
            <Typography variant={"body2"} color={theme.palette.error.main}>
              {authError}
            </Typography>
          )}
          <LoadingButton
            loading={authStatus === fetchStatuses.pending}
            variant={"outlined"}
            size={"large"}
            type={"submit"}
            fullWidth
            onClick={() => {
              dispatch(userApi.authorization({ email, password }));
            }}
          >
            Войти
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Auth;
