import { $hubApi } from "../http/index";

export default class authService {
  static async identification(uuid) {
    const params = new URLSearchParams();
    params.append("uuid", uuid);
    return $hubApi.post(`/user/identification`, params);
  }
  static async registration({ email, password }) {
    return $hubApi.post(`/user/register`, { email, password });
  }
  static async authorization({ email, password }) {
    return $hubApi.post(`/user/authorization`, { email, password });
  }
}
