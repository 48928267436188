import React from "react";
import Grid from "@mui/material/Grid";
import GamePlayersList from "./GamePlayersList";
import Typography from "@mui/material/Typography";
import RegenerateInvites from "./RegenerateInvites";

const GamePlayersWrapper = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant={"h5"}>Игроки</Typography>
      </Grid>
      <RegenerateInvites />
      <Grid item xs={12}>
        <GamePlayersList />
      </Grid>
    </Grid>
  );
};

export default GamePlayersWrapper;
