import React from "react";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { gamePlayersUpdateOne } from "../../../../../store/games/factorySlice";
import { jobsSelectors } from "../../../../../store/games/factorySelectors";

const GamePlayerJobSelector = ({ player }) => {
  const dispatch = useDispatch();
  const jobs = useSelector(jobsSelectors.selectAll);
  const activeRoundId = useSelector((state) => state.factory.activeRoundId);
  const handleChange = (event) => {
    dispatch(
      gamePlayersUpdateOne({
        id: player.id,
        changes: { jobId: event.target.value },
      })
    );
  };
  return (
    <FormControl fullWidth size={"small"} variant={"standard"}>
      <Select
        value={player.jobId ? player.jobId : ""}
        label="Age"
        onChange={handleChange}
        disabled={activeRoundId !== null}
      >
        {jobs.map((item) => {
          return (
            <MenuItem value={item.id} key={item.id}>
              {item.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default GamePlayerJobSelector;
