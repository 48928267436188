import { createEntityAdapter } from "@reduxjs/toolkit";

export const jobsAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.sortOrder - b.sortOrder,
});

export const bonusesAdapter = createEntityAdapter();
export const botsAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.sortOrder - b.sortOrder,
});
export const gamesAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.id - a.id,
});

export const gameInvitesAdapter = createEntityAdapter();
export const gamePlayersAdapter = createEntityAdapter();
export const gameRoundsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.id - a.id,
});

export const productsAdapter = createEntityAdapter();

export const roundProductsAdapter = createEntityAdapter();

export const roundReportAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.id - b.id,
});

export const hubsAdapter = createEntityAdapter();
export const transportsAdapter = createEntityAdapter();
export const roundTransportsAdapter = createEntityAdapter();
export const roundHubsAdapter = createEntityAdapter();

export const roundHubsProductsAdapter = createEntityAdapter();
