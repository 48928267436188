import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import factoryService from "../../../../../../../services/factoryService";
import {
  gameRoundsUpdateOne,
  setActiveRound,
  setActiveRoundId,
  setSelectedRoundId,
} from "../../../../../../../store/games/factorySlice";

const EndRoundButton = () => {
  const dispatch = useDispatch();
  const { gameId } = useParams();
  const activeRoundId = useSelector((state) => state.factory.activeRoundId);
  const activeRound = useSelector((state) => state.factory.activeRound);

  const [loading, setLoading] = useState(false);

  return (
    <LoadingButton
      disabled={activeRoundId === null}
      loading={loading}
      size={"small"}
      variant={"outlined"}
      onClick={async () => {
        setLoading(true);
        const response = await factoryService.endRound({
          gameId,
        });
        if (response.data.status === "ok") {
          if (activeRound !== null) {
            dispatch(
              gameRoundsUpdateOne({
                id: activeRound.id,
                changes: { datetimeEnded: Math.floor(Date.now() / 1000) },
              })
            );
            dispatch(setSelectedRoundId({ id: activeRound.id }));
          }
          dispatch(setActiveRoundId({ id: null }));
          dispatch(setActiveRound({ activeRound: null }));
        }
        setLoading(false);
      }}
    >
      Завершить раунд
    </LoadingButton>
  );
};

export default EndRoundButton;
