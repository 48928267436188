import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  factoryApi,
  setActiveRound,
} from "../../../../../store/games/factorySlice";
import { gameRoundUpdateInterval } from "../../../../../other/constants";

const RoundUpdater = () => {
  const dispatch = useDispatch();
  const activeRoundId = useSelector((state) => state.factory.activeRoundId);
  const promiseRef = useRef(null);

  useEffect(() => {
    let interval = null;
    if (activeRoundId !== null) {
      promiseRef.current = dispatch(
        factoryApi.getRoundInfo({ roundId: activeRoundId })
      );
      interval = setInterval(() => {
        promiseRef.current = dispatch(
          factoryApi.getRoundInfo({ roundId: activeRoundId })
        );
      }, gameRoundUpdateInterval);
    }
    if (activeRoundId === null) {
      dispatch(setActiveRound({ activeRound: null }));
    }

    return () => {
      if (interval !== null) {
        clearInterval(interval);
      }
      if (promiseRef.current !== null) {
        promiseRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRoundId]);

  return null;
};

export default RoundUpdater;
