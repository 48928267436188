import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import RoundsTabs from "./RoundsTabs";
import Bonuses from "./Bonuses";
import Players from "../../Players/CompletedRoundPlayers/Players";
import { useSelector } from "react-redux";
import RoundReportWrapper from "./RoundReport/RoundReportWrapper";

const CompletedRounds = () => {
  const selectedRoundId = useSelector((state) => state.factory.selectedRoundId);
  if (selectedRoundId === null) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant={"h5"}>В игре нет завершенных раундов</Typography>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant={"h5"}>Завершенные раунды игры</Typography>
      </Grid>
      <Grid item xs={12}>
        <RoundsTabs />
      </Grid>
      <Grid item xs={12}>
        <Bonuses />
      </Grid>
      <Grid item xs={12}>
        <Players />
      </Grid>
      <Grid item xs={12}>
        <RoundReportWrapper />
      </Grid>
    </Grid>
  );
};

export default CompletedRounds;
