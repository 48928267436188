import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

const AppsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    navigate(`app/factory`);
  }, [dispatch, navigate]);
  return <Box />;
};

export default AppsList;
