import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PdfGenerator from "./PdfGenerator";
import { useWindowDimensions } from "../../../../../../../other/hooks";
import { useSelector } from "react-redux";
import { roundReportSelectors } from "../../../../../../../store/games/factorySelectors";

const Finance = () => {
  const selectedRoundId = useSelector((state) => state.factory.selectedRoundId);
  const roundReport = useSelector((state) =>
    roundReportSelectors.selectById(state, selectedRoundId)
  );
  //only for page update on resize
  // eslint-disable-next-line no-unused-vars
  const { width } = useWindowDimensions();
  const [pdf, setPdf] = useState(null);
  const ref = useRef(null);
  let height = 500;

  useEffect(() => {
    setPdf(null);
    // setPdf(PdfGenerator.generate(roundReport));
    PdfGenerator.generateAsync(roundReport).then((result) => {
      setPdf(result);
    });
  }, [roundReport]);

  if (ref.current !== null) {
    height = ref.current.getBoundingClientRect().width / 1.414 + 20;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant={"h5"}>Финансовый результат раунда</Typography>
      </Grid>
      <Grid item xs={12} ref={ref}>
        {pdf !== null && (
          <iframe
            title={"RoundResults"}
            src={`${pdf}#view=Fit`}
            style={{
              overflow: "hidden",
              display: "block",
              border: "none",
              height: height,
              width: "100%",
            }}
            width={"100%"}
            scrolling="no"
            frameBorder="0"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Finance;
