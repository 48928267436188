import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { titleFont, tooltip } from "./defaults";
import { useSelector } from "react-redux";
import { roundReportSelectors } from "../../../../../../../store/games/factorySelectors";

const map = {
  centralHubRent: "Аренда ЦС",
  hubsRent: "Аренда цехов",
  wages: "ФОТ",
  generalRunningCosts: "ОХР",
  suppliesCosts: "Переменные расходы",
  bonusesCosts: "ОМ",
  resultNzpFine: "Штраф за НЗП",
};

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
  interaction: {
    intersect: true,
  },
  plugins: {
    legend: {
      position: "top",
      display: true,
    },
    tooltip,
    title: {
      text: "Структура расходов",
      font: titleFont,
    },
  },
};

const CostsPie = () => {
  const selectedRoundId = useSelector((state) => state.factory.selectedRoundId);
  const roundReport = useSelector((state) =>
    roundReportSelectors.selectById(state, selectedRoundId)
  );
  const financeCalculated = roundReport.financeCalculated;
  const resultNzpFine = roundReport.resultNzpFine;
  const data = Object.keys(map).reduce(
    (obj, key) => {
      if (key === "resultNzpFine") {
        return obj;
      }
      if (Math.abs(financeCalculated[key]) === 0) {
        return obj;
      }
      obj.labels.push(map[key]);
      obj.datasets[0].data.push(Math.abs(financeCalculated[key]));
      return obj;
    },
    { labels: [], datasets: [{ data: [], borderWidth: 1 }] }
  );
  if (Math.abs(resultNzpFine) !== 0) {
    data.labels.push(map.resultNzpFine);
    data.datasets[0].data.push(Math.abs(resultNzpFine));
  }

  return <Doughnut data={data} options={options} />;
};

export default CostsPie;
