import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import {
  hubsSelectors,
  roundReportSelectors,
} from "../../../../../../../store/games/factorySelectors";
import { scales, titleFont, tooltip } from "./defaults";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  scales,
  plugins: {
    tooltip,
    title: {
      text: "Штраф НЗП по складам",
      font: titleFont,
    },
  },
};

const NzpByHubs = () => {
  const hubs = useSelector(hubsSelectors.selectEntities);
  const selectedRoundId = useSelector((state) => state.factory.selectedRoundId);
  const roundReport = useSelector((state) =>
    roundReportSelectors.selectById(state, selectedRoundId)
  );
  const roundHubs = roundReport.gameHubs;

  const data = roundHubs
    .filter((item) => {
      return item.hubId <= 6;
    })
    .reduce(
      (obj, item) => {
        obj.labels.push(hubs[item.hubId].name);
        obj.datasets[0].data.push(item.nzpFine === null ? 0 : -item.nzpFine);

        return obj;
      },
      { labels: [], datasets: [{ data: [] }] }
    );

  return <Bar options={options} data={data} />;
};

export default NzpByHubs;
