import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import decamelize from "decamelize";
import LoadingButton from "@mui/lab/LoadingButton";
import factoryService from "../../../../../../../services/factoryService";
import {
  bonusesSelectors,
  botsSelectors,
  gamePlayersSelectors,
} from "../../../../../../../store/games/factorySelectors";
import Typography from "@mui/material/Typography";
import { setActiveRoundId } from "../../../../../../../store/games/factorySlice";

const map = {
  isBonusMachineAction4: "is_bonus_machine_action_4",
  isBonusMachineAction8: "is_bonus_machine_action_8",
  isBonusMachineAction9: "is_bonus_machine_action_9",
  isBonusMachineAction12: "is_bonus_machine_action_12",
};

const StartRoundButton = () => {
  const dispatch = useDispatch();
  const { gameId } = useParams();
  const activeRoundId = useSelector((state) => state.factory.activeRoundId);

  const gamePlayers = useSelector(gamePlayersSelectors.selectAll);
  const bonuses = useSelector(bonusesSelectors.selectAll);
  const bots = useSelector(botsSelectors.selectAll);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  return (
    <>
      {error !== null && <Typography color={"error"}>{error}</Typography>}
      <LoadingButton
        disabled={activeRoundId !== null}
        loading={loading}
        size={"small"}
        variant={"outlined"}
        onClick={async () => {
          setLoading(true);
          try {
            const gamePlayersArr = gamePlayers.map((gamePlayer) => {
              return {
                userId: gamePlayer.userId,
                jobId: gamePlayer.jobId ? gamePlayer.jobId : "",
              };
            });
            const bonusesArr = bonuses.map((bonus) => {
              let id = decamelize(bonus.id);
              if (map.hasOwnProperty(bonus.id)) {
                id = map[bonus.id];
              }
              return {
                id: id,
                checked: bonus.checked ? "Y" : "N",
              };
            });
            const botsArr = bots.map((bot, index) => {
              const decamelized = decamelize(bot.id);
              return {
                id: `${decamelized.slice(0, 10)}_${decamelized.slice(10)}`,
                checked: bot.checked ? "Y" : "N",
              };
            });
            const response = await factoryService.startRound({
              gameId,
              gamePlayers: gamePlayersArr,
              bonuses: bonusesArr,
              bots: botsArr,
            });
            if (response.data.status === "ok") {
              setError(null);
              dispatch(
                setActiveRoundId({ id: response.data.data.gameRound.id })
              );
            } else {
              setError(
                "Не удалось запустить раунд. Проверьте настройки раунда."
              );
            }
          } catch (e) {
            setError(
              "Не удалось запустить раунд. " + e.response.data.errors.join(" ")
            );
          }

          setLoading(false);
        }}
      >
        Запустить раунд
      </LoadingButton>
    </>
  );
};

export default StartRoundButton;
