import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const InfoCenterDivider = ({ color, text, textColor = "black" }) => {
  return (
    <Grid item xs={12}>
      <Box
        sx={{
          width: "100%",
          backgroundColor: color,
          color: textColor,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "36px",
          borderRadius: 2,
        }}
      >
        <Typography variant={"button"}>{text}</Typography>
      </Box>
    </Grid>
  );
};

export default InfoCenterDivider;
