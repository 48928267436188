import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CurrentRoundTime from "./CurrentRoundTime";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import EndRoundMinutesController from "./EndRoundMinutesController";

const TimerCard = () => {
  const [showController, setShowController] = useState(false);
  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant={"subtitle2"}>Время раунда</Typography>
            <Typography variant={"h6"}>
              <CurrentRoundTime />
            </Typography>
          </Box>
          <Box>
            <IconButton
              size={"large"}
              color={"primary"}
              onClick={() => {
                setShowController(!showController);
              }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Box>
        {showController && (
          <EndRoundMinutesController setShowController={setShowController} />
        )}
      </CardContent>
    </Card>
  );
};

export default TimerCard;
