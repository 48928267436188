import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Order from "./Order";
import { useSelector } from "react-redux";
import { roundReportSelectors } from "../../../../../../../store/games/factorySelectors";

const Orders = () => {
  const selectedRoundId = useSelector((state) => state.factory.selectedRoundId);
  const roundReport = useSelector((state) =>
    roundReportSelectors.selectById(state, selectedRoundId)
  );
  const ordersResults = roundReport.gameOrders;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant={"h6"}>Результаты по заказам раунда</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} display={"flex"}>
          {ordersResults.map((order) => {
            return (
              <Grid item xs={6} md={4} key={order.number}>
                <Order order={order} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Orders;
