import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PlayersOnlineCount from "./PlayersOnlineCount";

const PlayersCountCard = () => {
  return (
    <Card>
      <CardContent>
        <Box>
          <Typography variant={"subtitle2"}>Игроков онлайн</Typography>
          <Typography variant={"h6"}>
            <PlayersOnlineCount />
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PlayersCountCard;
