import $api from "../http/index";
import decamelizeKeys from "decamelize-keys";

export default class factoryService {
  static async appInfo() {
    return $api.get(`/info`);
  }
  static async jobs() {
    return $api.post(`/jobs`);
  }
  static async gameInfo({ gameId }) {
    return $api.post(`/game/${gameId}/info`);
  }
  static async gamePlayers({ gameId }) {
    return $api.post(`/game/${gameId}/players`);
  }
  static async gameRounds({ gameId }) {
    return $api.post(`/game/${gameId}/rounds`);
  }
  static async roundInfo({ roundId }) {
    return $api.post(`/game/round/${roundId}/admininfo`);
  }
  static async roundProductInfo({ roundId }) {
    return $api.post(`/game/round/${roundId}/moreinfo`);
  }
  static async setEndRoundMinutes({ gameId, minutes }) {
    const data = new FormData();
    data.append("value", minutes);
    return $api.post(`/game/${gameId}/setMinutes`, data);
  }
  static async bonusInfo() {
    return $api.get(`/info/bonus`);
  }
  static async gameReport({ gameId }) {
    return $api.post(`/game/${gameId}/report`);
  }
  static async roundReport({ roundId }) {
    return $api.post(`/game/round/${roundId}/report`);
  }
  static async startRound({ gameId, bonuses, bots, gamePlayers }) {
    const data = new FormData();
    gamePlayers.forEach((player, index) => {
      data.append(`players[${index}][user_id]`, player.userId);
      data.append(`players[${index}][job_id]`, player.jobId);
    });
    bonuses.forEach((bonus) => {
      data.append(bonus.id, bonus.checked);
    });
    bots.forEach((bot) => {
      data.append(bot.id, bot.checked);
    });
    return $api.post(`/game/${gameId}/start`, data);
  }
  static async endRound({ gameId }) {
    const data = new FormData();
    data.append("is_finish", "N");
    return $api.post(`/game/${gameId}/end`, data);
  }
  static async games() {
    return $api.post(`/games`);
  }
  static async createGame() {
    return $api.post(`/game/create`);
  }
  static async createInvites({ gameId, invites }) {
    return $api.post(`/game/${gameId}/invites`, { invites });
  }
  static async getLeaderboard(criteria) {
    criteria = decamelizeKeys(criteria);
    criteria["where[ended_at_to]"] =
      criteria["where[ended_at_to]"].format("YYYY-MM-DD");
    criteria["where[ended_at_from]"] =
      criteria["where[ended_at_from]"].format("YYYY-MM-DD");
    const params = new URLSearchParams();
    Object.keys(criteria).forEach((key) => {
      const item = criteria[key];
      if (item !== null) {
        params.append(key, item);
      }
    });
    return $api.post(`game/round/leaders`, null, {
      params: params,
    });
  }
}
