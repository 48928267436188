import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { useSelector } from "react-redux";
import { LinearProgress } from "@mui/material";
import EndRoundButton from "./EndRoundButton";
import StartRoundButton from "./StartRoundButton";
import SmartDisplayOutlinedIcon from "@mui/icons-material/SmartDisplayOutlined";

const PlayersCountCard = () => {
  const activeRoundId = useSelector((state) => state.factory.activeRoundId);
  const activeRound = useSelector((state) => state.factory.activeRound);

  const [showEndRoundButton, setShowEndRoundButton] = useState(false);
  const [showStartRoundButton, setShowStartRoundButton] = useState(false);

  const statusText =
    activeRoundId !== null && activeRound === null
      ? "Загрузка раунда..."
      : activeRoundId === null
      ? "Неактивен"
      : `Активен (№${activeRound.number})`;

  useEffect(() => {
    setShowStartRoundButton(false);
    setShowEndRoundButton(false);
  }, [activeRoundId]);

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant={"subtitle2"}>Статус раунда</Typography>
            <Typography variant={"h6"}>{statusText}</Typography>
          </Box>
          {activeRound === null && activeRoundId === null && (
            <Box>
              <IconButton
                color={"success"}
                size={"large"}
                onClick={() => {
                  setShowStartRoundButton(!showStartRoundButton);
                }}
              >
                <SmartDisplayOutlinedIcon />
              </IconButton>
            </Box>
          )}
          {activeRound !== null && (
            <Box>
              <IconButton
                size={"large"}
                color={"error"}
                onClick={() => {
                  setShowEndRoundButton(!showEndRoundButton);
                }}
              >
                <CancelPresentationIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        {showStartRoundButton && (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <StartRoundButton />
          </Box>
        )}
        {showEndRoundButton && (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <EndRoundButton />
          </Box>
        )}
        {activeRoundId !== null && activeRound === null && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default PlayersCountCard;
