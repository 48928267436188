import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Bonus from "./Bonus";
import { useSelector } from "react-redux";
import {
  bonusesSelectors,
  gameRoundsSelectors,
} from "../../../../../store/games/factorySelectors";

const Bonuses = () => {
  const selectedRoundId = useSelector((state) => state.factory.selectedRoundId);
  const round = useSelector((state) =>
    gameRoundsSelectors.selectById(state, selectedRoundId)
  );
  const bonuses = useSelector(bonusesSelectors.selectAll);

  let hasBonuses = false;
  for (let i = 0; i < bonuses.length; i++) {
    const bonus = bonuses[i];
    if (round.isNew || round[bonus.id] === "Y") {
      hasBonuses = true;
      break;
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant={"h5"}>
          Оптимизационные мероприятия завершенного раунда
        </Typography>
      </Grid>
      {!hasBonuses && (
        <Grid item xs={12}>
          <Typography>
            В раунде не было включенных оптимизационных мероприятий
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container>
          {bonuses.map((bonus) => {
            if (round[bonus.id] === "N") {
              return null;
            }
            return (
              <Bonus
                label={bonus.description}
                checked={round[bonus.id] === "Y"}
                key={bonus.id}
              />
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Bonuses;
