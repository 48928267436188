import React from "react";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { botsSelectors } from "../../../../../../store/games/factorySelectors";
import Typography from "@mui/material/Typography";
import Bot from "./Bot";

const Bots = () => {
  const bots = useSelector(botsSelectors.selectAll);
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant={"h5"}>Боты</Typography>
      </Grid>
      {bots.map((bot) => {
        return <Bot bot={bot} key={bot.id} />;
      })}
    </Grid>
  );
};

export default Bots;
