import React from "react";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { gamePlayersSelectors } from "../../../../../store/games/factorySelectors";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Player from "./Player";

const PlayersList = () => {
  const list = useSelector(gamePlayersSelectors.selectAll);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell>Логин</TableCell>
                <TableCell>Комментарий</TableCell>
                <TableCell>Роль в раунде</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((player) => {
                return <Player key={player.id} player={player} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default PlayersList;
