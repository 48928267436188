import React from "react";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedRoundId } from "../../../../../store/games/factorySlice";
import { gameRoundsSelectors } from "../../../../../store/games/factorySelectors";

const RoundsTabs = () => {
  const dispatch = useDispatch();
  const rounds = useSelector(gameRoundsSelectors.selectAll);
  const selectedRoundId = useSelector((state) => state.factory.selectedRoundId);
  return (
    <Tabs
      value={selectedRoundId}
      onChange={(e, newValue) => {
        dispatch(setSelectedRoundId({ id: newValue }));
      }}
      variant="scrollable"
      scrollButtons
      sx={{
        [`& .${tabsClasses.scrollButtons}`]: {
          "&.Mui-disabled": { opacity: 0.3 },
        },
      }}
    >
      {rounds.map((round) => {
        if (round.datetimeEnded === null) {
          return null;
        }
        return <Tab label={round.number} value={round.id} key={round.id} />;
      })}
    </Tabs>
  );
};

export default RoundsTabs;
