import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Header";
import AppsHub from "./GameHub/AppsHub";
import Factory from "./Apps/Factory/Factory";
import GameWrapper from "./Apps/Factory/GameWrapper";
import { useSelector } from "react-redux";
import { fetchStatuses, maxAppWidth } from "../other/constants";
import Auth from "./Auth/Auth";
import LoadHubData from "./GameHub/LoadHubData";
import Leaderboard from "./Apps/Factory/Leaderboard/Leaderboard";
import Container from "@mui/material/Container";

const Routing = () => {
  const authStatus = useSelector((state) => state.user.authStatus);
  const hubReady = useSelector((state) => state.hub.dataLoading);
  if (authStatus !== fetchStatuses.fulfilled) {
    return <Auth />;
  }
  if (hubReady !== fetchStatuses.fulfilled) {
    return <LoadHubData />;
  }
  return (
    <Container maxWidth={maxAppWidth}>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path={"/"} element={<AppsHub />} />
          <Route path={"/app/factory"} element={<Factory />} />
          <Route path={"/app/factory/game/:gameId"} element={<GameWrapper />} />
          <Route path={"/app/factory/leaderboard"} element={<Leaderboard />} />
        </Routes>
      </BrowserRouter>
    </Container>
  );
};

export default Routing;
