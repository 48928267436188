import React from "react";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { bonusesSelectors } from "../../../../../../store/games/factorySelectors";
import Typography from "@mui/material/Typography";
import Bonus from "./Bonus";

const Bonuses = () => {
  const bonuses = useSelector(bonusesSelectors.selectAll);
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant={"h5"}>
          Настройки оптимизационных мероприятий
        </Typography>
      </Grid>
      {bonuses.map((bonus) => {
        return <Bonus bonus={bonus} key={bonus.id} />;
      })}
    </Grid>
  );
};

export default Bonuses;
