import React, { useCallback, useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import ReactFlow, {
  applyNodeChanges,
  Controls,
  MiniMap,
  useEdgesState,
  useNodesInitialized,
  useNodesState,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";
import Box from "@mui/material/Box";
import ProductNode from "./ProductNode";
import { useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { roundProductsSelectors } from "../../../../../../../store/games/factorySelectors";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { productsInitialState } from "../../../../../../../other/productsConstants";

const ProductsFlow = () => {
  const [nodes, setNodes] = useNodesState(productsInitialState);
  const nodesInitialized = useNodesInitialized();
  const { fitView } = useReactFlow();
  const theme = useTheme();
  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const selectedRoundId = useSelector((state) => state.factory.selectedRoundId);
  const selectedRoundProducts = useSelector((state) =>
    roundProductsSelectors.selectById(state, selectedRoundId)
  );

  useEffect(() => {
    if (selectedRoundProducts !== undefined) {
      setNodes(
        nodes.map((node) => {
          return {
            ...node,
            data: {
              ...node.data,
              ...selectedRoundProducts.products[node.id],
            },
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoundProducts]);

  useEffect(() => {
    if (nodesInitialized) {
      fitView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodesInitialized]);

  const [edges] = useEdgesState(
    productsInitialState.map((product) => {
      return {
        id: `e${product.id}-${product.data.nextProductId}`,
        source: product.id,
        target: String(product.data.nextProductId),
        animated: true,
        selected: false,
        interactionWidth: 1,
        style: { stroke: theme.palette.primary.main, strokeWidth: "2px" },
        markerEnd: {
          type: "arrowclosed",
          color: theme.palette.primary.main,
        },
        focusable: false,
      };
    })
  );

  const nodeTypes = useMemo(() => ({ productNode: ProductNode }), []);

  const reactFlowStyle = {};

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant={"h6"}>Продукты раунда</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          component={Paper}
          elevation={1}
          sx={{
            width: "100%",
            height: "800px",
          }}
        >
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            onNodesChange={onNodesChange}
            style={reactFlowStyle}
          >
            <MiniMap />
            <Controls />
            {/*<Background />*/}
          </ReactFlow>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ProductsFlow;
