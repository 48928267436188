import React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

const ColoredIndicator = ({ color }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "12px",
        height: "12px",
        borderRadius: "4px",
        backgroundColor: color || theme.palette.success.light,
        marginRight: theme.spacing(1),
      }}
    />
  );
};

export default ColoredIndicator;
