import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { roundReportSelectors } from "../../../../../../../store/games/factorySelectors";
import { scales, titleFont, tooltip } from "./defaults";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      text: "% брака по раундам",
      font: titleFont,
    },
    tooltip,
  },
  scales,
};

const DefectPercentByRounds = () => {
  const roundsResults = useSelector(roundReportSelectors.selectAll);

  const data = roundsResults.reduce(
    (result, round) => {
      result.labels.push(`Раунд №${round.number}`);
      result.datasets[0].data.push(
        round.resultProducts === 0
          ? 0
          : (
              ((round.resultProducts - round.resultProductsTrue) * 100) /
              round.resultProducts
            ).toFixed(2)
      );
      return result;
    },
    {
      labels: [],
      datasets: [
        {
          label: "Брак в раунде",
          maxBarThickness: 120,
          data: [],
        },
      ],
    }
  );

  return <Bar options={options} data={data} />;
};

export default DefectPercentByRounds;
