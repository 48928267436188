import React from "react";
import Grid from "@mui/material/Grid";
import CurrentRound from "./Rounds/CurrentRound/CurrentRound";
import CompletedRounds from "./Rounds/CompletedRounds/CompletedRounds";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";

const Game = () => {
  const game = useSelector((state) => state.factory.gameInfo);

  return (
    <Grid container spacing={3}>
      <Grid item />
      {game.datetimeEnded !== null && (
        <Grid item xs={12}>
          <Typography variant={"h5"}>Игра завершена</Typography>
        </Grid>
      )}
      {game.datetimeEnded === null && (
        <Grid item xs={12}>
          <CurrentRound />
        </Grid>
      )}
      <Grid item xs={12}>
        <CompletedRounds />
      </Grid>
    </Grid>
  );
};

export default Game;
