import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FilterByDate from "./FilterByDate";
import FilterByRoundTime from "./FilterByRoundTime";

const Filters = ({ currCriteria, handleNewCriteria }) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant={"h6"}>Фильтры</Typography>
          </Grid>
          <FilterByDate
            currCriteria={currCriteria}
            handleNewCriteria={handleNewCriteria}
          />
          <FilterByRoundTime
            currCriteria={currCriteria}
            handleNewCriteria={handleNewCriteria}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Filters;
