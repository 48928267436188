import "./App.css";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import Routing from "./Components/Routing";
import { ruRU } from "@mui/material/locale";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/ru";

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "rgb(2,94,161)",
        dark: "rgb(0,50,116)",
        light: "rgb(108,172,228)",
      },
      success: {
        main: "rgb(37,151,137)",
        dark: "rgb(37,151,137)",
        light: "rgb(86,192,43)",
      },
      warning: {
        main: "rgb(253,105,37)", //"rgb(226,0,122)",
        dark: "rgb(253,105,37)",
        light: "rgb(252,195,11)",
      },
      // error: {
      //   main: "rgb(226,0,122)",
      //   dark: "rgb(253,105,37)",
      //   light: "rgb(253,105,37)",
      // },
    },
  },
  ruRU
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"ru"}>
        <Routing />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
