import title from "../../../../../../../media/images/pdf/title.png";
import infopanel from "../../../../../../../media/images/pdf/infopanel.png";
import arrow from "../../../../../../../media/images/pdf/arrow.png";
import "../../../../../../../media/fonts/Rosatom-normal";
import "../../../../../../../media/fonts/RosatomBold-bold";
import background from "./../../../../../../../media/images/pdf/background.png";
import fullBackground from "./../../../../../../../media/images/pdf/fullbackground.jpg";
import jsPDF from "jspdf";

export default class PdfGenerator {
  static generateAsync(roundReport) {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas"),
        context = canvas.getContext("2d");
      const base_image = new Image();
      base_image.src = fullBackground;
      base_image.onload = () => {
        canvas.width = base_image.width;
        canvas.height = base_image.height;
        context.drawImage(
          base_image,
          0,
          0,
          base_image.width,
          base_image.height
        );

        const pdf = new jsPDF({ orientation: "landscape", compress: false });
        const data = context.getImageData(
          0,
          0,
          base_image.width,
          base_image.height
        );
        pdf.addImage(data, "JPG", 0, 0, 297, 210);
        this._titles(pdf, false);
        this._finance(pdf, roundReport.financeCalculated);
        this._stats(pdf, roundReport, false);
        const pdfData = pdf.output("bloburi");
        resolve(pdfData);
      };
    });
  }

  static generate(roundReport) {
    const pdf = new jsPDF({ orientation: "landscape", compress: true });
    pdf.text("РЕЗУЛЬТАТЫ РАУНДА", 7.6, 21);
    const w = 209.043;
    const h = 75.565;
    const x = 297 - w;
    const y = 0;
    pdf.addImage(background, "png", x, y, w, h);
    this._titles(pdf);
    this._finance(pdf, roundReport.financeCalculated);
    this._stats(pdf, roundReport);
    return pdf.output("bloburi");
  }

  static _titles(pdf, addImages = true) {
    if (addImages) {
      pdf.addImage(title, "png", 0, 13, 76, 12.6);
    }
    pdf.setFont("RosatomBold", "bold");
    pdf.setFontSize(14.9);
    pdf.setTextColor("#ffffff");
    pdf.text("РЕЗУЛЬТАТЫ РАУНДА", 7.6, 21);
    pdf.setTextColor("#000000");
    pdf.text("Финансовые результаты", 7.6, 41.4);
    pdf.text("Нефинансовые результаты", 7.6, 150);
    pdf.setDrawColor("#5ab7e4");
    pdf.setFillColor("#5ab7e4");
    pdf.rect(8, 43.5, 68.9, 1, "FD");
    pdf.setDrawColor("#be2a70");
    pdf.setFillColor("#be2a70");
    pdf.rect(8, 152.1, 69.1, 1, "FD");
  }

  static _stats(pdf, roundReport, addImages = true) {
    const { tvsTotal, tvsInTime, revenueLost } = roundReport.gameOrders.reduce(
      (result, item) => {
        if (item.datetimeExecution !== null) {
          result.tvsTotal += item.countTvs;
          if (item.datetimeExecution <= item.datetimeExecutionPlan) {
            result.tvsInTime += item.countTvs;
          }
          result.revenueLost += item.resultRevenue - item.revenue;
        }
        return result;
      },
      { tvsTotal: 0, tvsInTime: 0, revenueLost: 0 }
    );
    if (addImages) {
      pdf.addImage(infopanel, "png", 7.6, 159, 282, 42);
      pdf.addImage(arrow, "png", 12.4, 174.5, 7.5, 11.5);
      pdf.addImage(arrow, "png", 82.4, 174.5, 7.5, 11.5);
      pdf.addImage(arrow, "png", 152.4, 174.5, 7.5, 11.5);
      pdf.addImage(arrow, "png", 222.4, 174.5, 7.5, 11.5);
    }

    let titlesY = 176;
    pdf.setFontSize(12);
    pdf.setTextColor("#000000");
    pdf.text("Процент брака", 29, titlesY, { maxWidth: 50 });
    pdf.text("Занятая площадь", 99, titlesY, { maxWidth: 50 });
    pdf.text("ТВС, поставленные вовремя", 169, titlesY, { maxWidth: 50 });
    pdf.text("Снижение выручки", 239, titlesY, { maxWidth: 50 });

    let valuesY = 188;
    pdf.setFontSize(17);
    pdf.setTextColor("#bd2a6e");
    pdf.text(
      roundReport.resultProducts === 0
        ? "0%"
        : `${(
            ((roundReport.resultProducts - roundReport.resultProductsTrue) *
              100) /
            roundReport.resultProducts
          ).toFixed(2)}%`,
      29,
      valuesY
    );
    pdf.text(`${roundReport.resultArea}`, 99, valuesY);
    pdf.text(`${tvsInTime}/${tvsTotal}`, 169, valuesY);
    pdf.text(`${revenueLost}`, 239, valuesY);
  }

  static _finance(pdf, financeResults) {
    this._result(pdf, "Фонд оплаты труда", String(financeResults.wages), 1, 1, {
      maxWidth: 40,
    });
    this._result(
      pdf,
      "Общехозяйственные расходы",
      String(financeResults.generalRunningCosts),
      2,
      1
    );
    this._result(
      pdf,
      "Аренда площадей цехов",
      String(financeResults.hubsRent + financeResults.centralHubRent),
      3,
      1
    );
    this._result(
      pdf,
      "Оптимизационные мероприятия",
      String(financeResults.bonusesCosts),
      4,
      1
    );
    this._result(
      pdf,
      "Штраф за несоответствие НЗП",
      String(financeResults.resultNzpFine),
      2,
      2
    );
    this._result(
      pdf,
      "Переменные расходы",
      String(financeResults.suppliesCosts),
      3,
      2,
      { maxWidth: 45 }
    );
    this._result(
      pdf,
      "Реальная выручка",
      String(financeResults.revenue),
      4,
      2,
      {
        maxWidth: 35,
      }
    );
    this._result(
      pdf,
      "Постоянные расходы",
      String(financeResults.fixedCosts),
      5,
      1,
      {
        maxWidth: 35,
        color: "#5ab8e5",
        reduceW: 5,
      }
    );
    this._result(
      pdf,
      "Маржинальный доход",
      String(financeResults.marginalIncome),
      5,
      2,
      {
        maxWidth: 35,
        color: "#5ab8e5",
        reduceW: 5,
      }
    );
    pdf.setDrawColor("#5ab8e5");
    pdf.setFillColor("#5ab8e5");
    pdf.roundedRect(248.1, 51.2, 42, 70, 2, 2, "FD");
    pdf.setTextColor("#000000");
    pdf.setFont("RosatomBold", "bold");
    pdf.setFontSize(14);
    pdf.text("Прибыль", 251, 84.5);
    pdf.text(String(financeResults.netProfit), 251, 92.2); //прибыль
  }

  static _result(pdf, text, value, posX, posY, params) {
    const _params = {
      maxWidth: 50,
      color: "#ceeaf7",
      reduceW: 0,
      ...params,
    };
    pdf.setTextColor("#000000");
    pdf.setDrawColor(_params.color);
    pdf.setFillColor(_params.color);
    const w = 47.5;
    const h = 23.3;
    const r = 2;
    const startY = 51.2;
    const startX = 7.6;
    const distanceX = 1.2;
    const distanceY = 23.4;
    const textOffsetX = 2;
    const textOffsetY = 10.1;
    const textStartX = startX + textOffsetX;
    const textStartY = startY + textOffsetY;
    const valueOffsetY = 23.8;
    const valueStartY = textStartY + valueOffsetY;
    pdf.roundedRect(
      startX + (w + distanceX) * (posX - 1),
      startY + (h + distanceY) * (posY - 1),
      w - _params.reduceW,
      h,
      r,
      r,
      "FD"
    );
    pdf.setFontSize(12);
    pdf.setFont("RosatomBold", "bold");
    pdf.text(
      text,
      textStartX + (w + distanceX) * (posX - 1),
      textStartY + (h + distanceY) * (posY - 1),
      { maxWidth: _params.maxWidth }
    );
    pdf.setFont("Rosatom", "regular", 400);
    pdf.setFontSize(14);
    pdf.text(
      value,
      textStartX + (w + distanceX) * (posX - 1),
      valueStartY + (h + distanceY) * (posY - 1)
    );
  }
}
