import React from "react";
import Grid from "@mui/material/Grid";
import Bonuses from "./Bonuses/Bonuses";
import GamePlayersWrapper from "../../Players/GamePlayers/GamePlayersWrapper";
import RoundUpdater from "./RoundUpdater";
import ControlPanel from "./ControlPanel/ControlPanel";
import Bots from "./Bots/Bots";

const CurrentRound = () => {
  return (
    <Grid container spacing={3}>
      <RoundUpdater />
      <Grid item xs={12}>
        <ControlPanel />
      </Grid>
      <Grid item xs={12}>
        <Bonuses />
      </Grid>
      <Grid item xs={12}>
        <Bots />
      </Grid>
      <Grid item xs={12}>
        <GamePlayersWrapper />
      </Grid>
    </Grid>
  );
};

export default CurrentRound;
