import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { factoryApi } from "../../../store/games/factorySlice";
import { gamesSelectors } from "../../../store/games/factorySelectors";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import LinearProgress from "@mui/material/LinearProgress";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { fetchStatuses } from "../../../other/constants";

const GamesList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /**
   * @param {Object[]} list
   * @param list[].id
   * @param list[].datetimeStartedUnix
   * @param list[].datetimeEndedUnix
   */
  const list = useSelector(gamesSelectors.selectAll);
  const loading = useSelector((state) => state.factory.games.loading);
  useEffect(() => {
    dispatch(factoryApi.getGames());
  }, [dispatch]);

  if (loading === fetchStatuses.rejected) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant={"h6"}>
            Ошибка при загрузке списка игр. Если ошибка будет повторяться
            обратитесь к нам: a.kostyan@elearnlab.ru
          </Typography>
        </Grid>
      </Grid>
    );
  }

  if (loading !== fetchStatuses.fulfilled) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant={"h5"}>Список игр</Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>id игры</TableCell>
                <TableCell>Игроки</TableCell>
                <TableCell>Раунды</TableCell>
                <TableCell>Дата начала</TableCell>
                <TableCell>Дата завершения</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <TableRow
                    key={item.id}
                    sx={{
                      cursor: "pointer",
                    }}
                    hover
                    onClick={() => {
                      navigate(`game/${item.id}`);
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {item.id}
                    </TableCell>
                    <TableCell>{item.countPlayers}</TableCell>
                    <TableCell>{item.countRounds}</TableCell>
                    <TableCell>
                      {item.datetimeStartedUnix === null
                        ? "-"
                        : new Date(
                            item.datetimeStartedUnix * 1000
                          ).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      {item.datetimeEndedUnix === null
                        ? "-"
                        : new Date(
                            item.datetimeEndedUnix * 1000
                          ).toLocaleDateString()}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, { label: "Все", value: -1 }]}
                  count={list.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={"Игр на странице:"}
                  labelDisplayedRows={function defaultLabelDisplayedRows({
                    from,
                    to,
                    count,
                  }) {
                    return `Игры ${from}–${to} из ${
                      count !== -1 ? count : `более чем ${to}`
                    }`;
                  }}
                  // ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default GamesList;
