import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import ColoredIndicator from "../../../../../../Shared/ColoredIndicator";

const Order = ({ order }) => {
  const isCompleted = order.datetimeExecution !== null;
  // const revenue = order.resultRevenue === null ? 0 : order.resultRevenue;
  const fine = order.resultFine === null ? 0 : order.resultFine;
  const resultProfit = order.resultProfit === null ? 0 : order.resultProfit;
  const theme = useTheme();
  return (
    <Card>
      <CardContent>
        <Typography variant={"h6"}>Заказ №{order.number}</Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ColoredIndicator
            color={
              isCompleted
                ? theme.palette.success.light
                : theme.palette.warning.dark
            }
          />
          <Typography variant={"body1"}>
            {isCompleted ? "Выполнен" : "Не выполнен"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ColoredIndicator
            color={
              !isCompleted
                ? theme.palette.warning.dark
                : order.executionDelay !== 0
                ? theme.palette.warning.light
                : theme.palette.success.light
            }
          />
          <Typography variant={"body1"}>
            {!isCompleted
              ? "Нет поставки"
              : order.executionDelay === 0
              ? "Вовремя"
              : `Опоздание на ${order.executionDelay}`}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant={"body1"}>Штраф:&nbsp;</Typography>
          <Typography variant={"body1"}> {fine} </Typography>
        </Box>
        {/*<Box sx={{ display: "flex", alignItems: "center" }}>*/}
        {/*  <Typography variant={"body1"}>ТВС в заказе:&nbsp;</Typography>*/}
        {/*  <Typography variant={"body1"}> {order.countTvs} </Typography>*/}
        {/*</Box>*/}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant={"body1"}>ТВС брак:&nbsp;</Typography>
          <Typography variant={"body1"}> {order.defectTvs} </Typography>
        </Box>
        <Typography variant={"body1"}>
          Штраф: {(order.revenue / order.countTvs) * order.defectTvs}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <Typography variant={"h6"}>Выручка:&nbsp;{resultProfit}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Order;
