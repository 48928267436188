import { configureStore } from "@reduxjs/toolkit";
import factoryReducer from "./games/factorySlice";
import userReducer from "./userSlice";
import hubReducer from "./hubSlice";

export default configureStore({
  reducer: {
    factory: factoryReducer,
    user: userReducer,
    hub: hubReducer,
  },
});
